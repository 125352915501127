
import { IoIosRefresh } from "react-icons/io";
import { Bars } from 'react-loading-icons'

const PaymentVerificationModal = ({ verifyingProcess, paymentSuccesserrorMessage, retryButton, setShowRetryButton, setPaymentsuccessErrorMessage,setRunVerification }) => {


    const handleRetry = ()=>{

        setShowRetryButton(false)
        setPaymentsuccessErrorMessage('')
        setRunVerification(true)
    }

    return <>

        <div className="backdrop" ></div>
        <main className=" w-[32rem] max-[568px]:w-[100%] rounded-xl bg-white px-[1rem] modal-modal border border-3 border-[grey] fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
            {verifyingProcess && <section>
                <div className="flex place-content-center py-[2rem]">
                    <Bars stroke="grey" height={50} fill="grey" className=" absolute left-[36%]" />
                </div>
                <h2 className="text-center font-bold text-[20px] small_pixel  pt-[2rem]">verifying payment .........</h2>
            </section>}
            <p className={`text-center small_pixel ${paymentSuccesserrorMessage ? 'py-[4rem]' : ''}  font-bold text-[1.2rem]`}>{paymentSuccesserrorMessage}</p>
            <div className="flex place-content-center">
                {retryButton && <IoIosRefresh fontSize={50} color="blue"
                    className="cursor-pointer rounded p-2 shadow-large" title="reload"
                    onClick={ handleRetry}
                />}
            </div>
        </main>
    </>
}

export default PaymentVerificationModal;