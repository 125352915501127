
import { useContext, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import PersonalInfo from "./RegisterRouteItems/PersonalInfo";
import Insurance from "./RegisterRouteItems/Insurance";
import CodeofConduct from "./RegisterRouteItems/CodeofConduct";
import ChoosePackage from "./RegisterRouteItems/ChoosePackage";
import DataContext from "../../../context/DataContext";

const Register = () => {

    const { state, textPersonColor, backgroundPersonColor,backgroundConductorColor,
        textInsuranceColor,textConductorColor,textPackageColor,backgroundInsuranceColor, 
        backgroundPackageColor,setTextPackageColor, setBackgroundPackageColor,setBackgroundInsuranceColor, setTextInsuranceColor,
        setBackgroundConductorColor,setTextConductorColor,setBackgroundPersonColor,setTextPersonColor } = useContext(DataContext)

        useEffect(()=>{
            if (state.personalInfo){
                setTextPersonColor('text-[#5030d2]')
                setBackgroundPersonColor('bg-[#5030d2]')
            } else{
               setTextPersonColor('text-[grey]')
               setBackgroundPersonColor('bg-[grey]')
            }

            if (state.insurance){
                setTextInsuranceColor('text-[#5030d2]')
                setBackgroundInsuranceColor('bg-[#5030d2]')
            }else{
                setTextInsuranceColor('text-[grey]')
                setBackgroundInsuranceColor('bg-[grey]')
            }

            if (state.codeOfConduct){
                setTextConductorColor('text-[#5030d2]')
                setBackgroundConductorColor('bg-[#5030d2]')
            }else{
                setTextConductorColor('text-[grey]')
                setBackgroundConductorColor('bg-[grey]')
            }
            if (state.choosePackage){
                setTextPackageColor('text-[#5030d2]')
                setBackgroundPackageColor('bg-[#5030d2]')
            }else{
                setTextPackageColor('text-[grey]')
                setBackgroundPackageColor('bg-[grey]')
            }
        },[state.personalInfo,state.insurance,state.codeOfConduct,state.choosePackage,setBackgroundPersonColor, setTextPersonColor,
            setBackgroundInsuranceColor, setTextInsuranceColor,setBackgroundConductorColor, setTextConductorColor,
            setBackgroundPackageColor, setTextPackageColor])

    return <>
     <Helmet>
            <title>Power of You 2024 - Registration</title>
            <meta name='description' content='register for power of you 2024' />
            <link rel="canonical" href="http://localhost:3000/register" />
        </Helmet>
        <main className="mt-[6rem] flex-grow">
            <section className="background-element h-[28.5rem] ">
                <div className="container  h-[100%]  flex items-center place-content-center ">
                    <div className="text-white text-center">
                        <h1 className="herokid-bold font-bold tracking-[0.3rem] text-center text-white text-[4rem] max-[526px]:text-[3rem]">REGISTER</h1>
                        <p className="small_pixel  text-[1.3rem] max-[526px]:text-[1rem]">THE CAMP IS ALL INCLUSIVE!!!</p>
                        <p className="small_pixel  text-[1.3rem] max-[526px]:text-[1rem]">YOUR REGISTRATION COVERS ALL FOOD , TRAVEL, LODGING AND PROGRAMMING</p>
                        <div className="container flex place-content-center ">
                            <p className="text-center py-[2.5rem] w-[50%] max-[526px]:w-[100%] small_pixel text-[1.3rem] max-[526px]:text-[0.9rem] ">
                                Registration will not be officially confirmed until payment of conference fees  are fully made
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-[#f1fcff] pb-[4rem]">
                
                <section className="container flex place-content-center items-center pt-[2rem]">
                    <div className="flex justify-between bg-[white] py-[2rem] px-4 rounded-xl w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%] shadow-medium herokid-regular text-[1.1rem] mt-[1.5rem] max-[768px]:text-[1rem]">
                        <p className={`${textPersonColor} font-bold`}><span className={`text-[white] ${backgroundPersonColor} px-[0.7rem] py-1  rounded-[50%] text-white font-semibold `}>1</span> <span className="max-[1026px]:hidden">Personal Info</span></p>
                        <p></p>
                        <p className={`${textInsuranceColor} font-bold`}> <span className= {`text-[white] ${backgroundInsuranceColor} px-[0.7rem] py-1  rounded-[50%] text-white font-semibold `}>2</span><span className="max-[1026px]:hidden"> Insurance/Waivers</span> </p>
                        <p></p>
                        <p className={`${textConductorColor} font-bold`}> <span className={`text-[white] ${backgroundConductorColor} px-[0.7rem] py-1  rounded-[50%] text-white font-semibold `}>3</span><span className="max-[1026px]:hidden"> Code of Conduct </span>  </p>
                        {/* <p></p> */}
                        {/* <p className={`${textPackageColor} font-bold`}><span className={`text-[white] ${backgroundPackageColor} px-[0.7rem] py-1  rounded-[50%] text-white font-semibold `}>4</span><span className="max-[1026px]:hidden"> Choose a Package</span> </p> */}
                    </div>
                </section>
                <section className="container flex place-content-center">
                    {state.personalInfo && <PersonalInfo />}
                    {state.insurance && <Insurance />}
                    {state.codeOfConduct && <CodeofConduct />}
                    {state.choosePackage && <ChoosePackage />}
                </section>
            </section>
        </main>
    </>
}

export default Register;


