
import { Link } from "react-router-dom";

const MissingPage = () => {

    return <>
        <main className="flex-grow flex place-content-center items-center">
            <div className="shadow-xlarge mt-[10rem] py-[4rem] px-[4rem] rounded-[2.5rem] w-[50%] max-[500px]:w-[100%] ">
                <h1 className="text-[8rem] max-[700px]:text-[4rem] text-center  herokid-bold">404</h1>
                <p className="small_pixel text-center mt-[-2rem] max-[700px]:text-[1.25rem] text-[2.5rem]"> oops! Page not found</p>
                <p className="small_pixel text-center text-[1.8rem] max-[700px]:text-[0.9rem]">The page you are looking for does not exist. It might have been moved or deleted</p>
                <section className="flex justify-center pt-[2.5rem]">
                  <Link to="/"> <button className="py-2 w-[15rem]  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18px]  cursor-pointer hover:bg-[blue]" >Back to Homepage</button></Link> 
                </section>
            </div>
        </main>
    </>
}

export default MissingPage;