
import { useInView } from 'react-intersection-observer';
// import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import tatiana from '../../../../images/tatiana.jpg'
import enoluwa from '../../../../images/Enioluwa.webp'
import Evans from '../../../../images/Evans.jpg'



const Guest = () => {

    const [ref, inView] = useInView({

    });

    const specialGuests = [
        {
            id: '1',
            firstName: 'Her Excellency Samira',
            lastName: ' Bawumia'
        },
        {
            id: '2',
            firstName: 'Franklin Owusu',
            lastName: 'Karikari'
        },
        {
            id: '3',
            firstName: 'Joyce ',
            lastName: 'Agyare'
        },
        {
            id: '4',
            firstName: 'Nana Yaw Sarpong',
            lastName: 'Siriboe'
        },
        {
            id: '5',
            firstName: 'Victor',
            lastName: 'Obeng-Adiyiah'
        },
        {
            id: '6',
            firstName: 'Kuukua',
            lastName: 'Eshun'
        },
        {
            id: '7',
            firstName: 'Emi-Beth',
            lastName: ' Quantson'
        },
        {
            id: '8',
            firstName: 'The',
            lastName: 'Characters'
        },
        {
            id: '9',
            firstName: 'Steeze',
            lastName: ' Afrika'
        }
    ]

    return <>
        <main className="bg-[#f1fcff] ">
            <section className={`container pt-[1.5rem] pb-[4rem] shadow-xlarge lg:px-[8rem]`} >
                <div className='relative'>
                    <h1 className="herokid-bold font-bold text-[black] text-[3rem]  max-[1025px]:text-[2rem] max-[401px]:text-[1.5rem]  border-[black] border-b-4 ">
                        Leading the Way
                    </h1>
                </div>
                <div className={`py-[2rem] pt-[3rem] mt-[-2rem] flex justify-between max-[1025px]:flex max-[1025px]:flex-col  transition-transform duration-1000 transform ${inView ? 'translate-x-0' : '-translate-x-full'} `} ref={ref} >
                    <section className='flex flex-col items-center '>
                        <div className='shadow-medium p-[2rem] rounded-[10%]  md:h-[35rem]'>
                            <img src={tatiana} alt="tatiana.jpg" className='filtblur h-[25rem] w-[20rem] max-[1285px]:h-[20rem] 
                            max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem]  object-cover rounded-[10%]' />
                            <h2 className='herokid-regular font-bold  text-[1.8rem] max-[430px]:text-[1.5rem]  text-[black] text-center filtblurtext'>Tatiana Manaois</h2>
                        </div>
                    </section>
                    <section className='flex flex-col items-center'>
                        <div className=' flex flex-col items-center shadow-medium p-[2rem] rounded-[10%] md:h-[35rem]'>
                            <img src={enoluwa} alt="enoluwa.png" className='filtblur h-[25rem] w-[20rem]  max-[1285px]:h-[20rem] 
                            max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem]  object-cover rounded-[10%]' />
                            <h2 className='herokid-regular font-bold text-[1.8rem] max-[430px]:text-[1.5rem] text-[black] text-center filtblurtext '>Adeoluwa Prince Enioluwa</h2>
                        </div>
                    </section>
                    <section className='flex flex-col items-center'>
                        <div className='shadow-medium p-[2rem] rounded-[10%] md:h-[35rem]'>
                            <img src={Evans} alt="evans.jpg" className='filtblur h-[25rem] w-[20rem]  max-[1285px]:h-[20rem] 
                            max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem]  object-cover rounded-[10%]' />
                            <h2 className='herokid-regular font-bold  text-[1.8rem] max-[430px]:text-[1.5rem] text-[black] text-center filtblurtext '>Evans Ofori Gyebi</h2>
                        </div>
                    </section>
                </div>
                <div className='relative pt-[1rem]'>
                    <h1 className="herokid-bold font-bold text-[black] text-[3rem] pt-[1rem] 
                    max-[1025px]:text-[2rem] max-[401px]:text-[1.5rem] border-[black] border-t-4 text-center">
                        With Special Guests
                    </h1>
                </div>

                <section className=''>
                    <section className='herokid-regular filtblurtext text-[1.2rem]'>
                        {specialGuests.map((item) => <div key={item.id} className='flex flex-row items-center place-content-center shadow-large'>
                            {/* <IoMdCheckmarkCircleOutline /> */}
                            <p className='text-[1.5rem] max-[464px]:text-[1rem] md:text-[2rem] lg:text-[2.5rem] '>{item.firstName}</p>
                            <p className='ml-2 font-bold text-[1.5rem] max-[464px]:text-[1rem]  md:text-[2rem] lg:text-[2.5rem] '>{item.lastName}</p>
                        </div>)}
                    </section>
                </section>
            </section>
        </main>
    </>
}


export default Guest;