
import { useState} from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';

const FAQItem = (item) => {
    const [showAnswer, setShowAnswer] = useState(false)
    const [showAccordDown, setShowAccordDown] = useState(true)
    const [showAccordUp, setShowAccordUp] = useState(false)
    const [bottomRadius, setBottomRadius] = useState('rounded-b-xl')
    // const [answerHeight, setAnswerHeight] = useState('0px');

//  useEffect(()=>{

//     if (showAnswer) {
//         setAnswerHeight('0px');
//         setTimeout(() => {
//           setShowAnswer(false);
//         }, 300); // Adjust the duration to match your CSS transition duration
//       } else {
//         setShowAnswer(true);
//         setAnswerHeight('auto');
//       }

//  }, [showAnswer])
    

    return <>

        <div className="faq py-2 flex place-content-center">
            <section className='w-[60%] max-[1278px]:w-[100%]'>
            <button className={`accordion herokid-regular font-bold tracking-[0.1rem] text-[1.15rem] text-white shadow-xlarge px-2 py-[2.5rem] flex items-center w-[100%] max-w-[40rem] rounded-t-xl ${bottomRadius}   relative`}>
                {item.item.question}
                {showAccordDown && <MdOutlineKeyboardArrowDown fontSize={22} className='absolute right-2 bg-[#6f73a3]  rounded-full' 
                    
                onClick={()=>{setShowAccordDown(false);setShowAccordUp(true);setBottomRadius(''); setShowAnswer(true)}}/> }

                { showAccordUp && <MdOutlineKeyboardArrowUp  fontSize={22} className='bg-[#6f73a3] absolute right-2 rounded-full'
                    onClick={()=>{setShowAccordDown(true);setShowAccordUp(false);setBottomRadius('rounded-b-xl'); setShowAnswer(false)}}
                />}
            </button>
            <div className='pannel text-white w-[100%] max-w-[40rem] text-[20px] small_pixel px-2  rounded-b-xl'
                
            >
               { showAnswer && <p className='py-5 animate-slideDown'>
                    {item.item.answer}
                </p>}
            </div>
            </section>
        </div>
    </>
}


export default FAQItem


