
import { useState, useContext, useEffect } from 'react'
import insurance from '../../../../InsuranceData/insurance.json'
import clause from '../../../../InsuranceData/clause.json'
import medicalwaiver from '../../../../InsuranceData/medicalWaiver.json'
import DataContext from '../../../../context/DataContext'
import ScrollToTop from '../ScrollToTop'



const Insurance = () => {

  const [nChecked, setNchecked] = useState(false)
  const [yChecked, setYchecked] = useState(false)
  const [ycChecked, setycChecked] = useState(false)
  const [insuranceFieldError, setInsuranceFieldError] = useState(false)
  // const [medWaiverChecked, setMedWaiverChecked] = useState(false)

  const { dispatch, personalInfoStateDispatch, personalInfoState } = useContext(DataContext)

  const insuranceStates = [
    personalInfoState.agree_to_assumption_terms,
    personalInfoState.assumption_of_risk_signature,
    personalInfoState.agree_to_medical_waiver_terms,
    personalInfoState.medical_wave_signature,
    personalInfoState.ward_has_insurance,
  ]

  const [assumptionChecked, setAssumptionChecked] = useState(() => {
    const storedAssumption = localStorage.getItem('assumptionChecked');
    return storedAssumption === 'true';
  });


  const [medWaiverChecked, setMedWaiverChecked] = useState(() => {
    const storedWaiver = localStorage.getItem('waiverChecked');
    return storedWaiver === 'true';
  });



  const continuePageHandler = () => {

    const isEmpty = insuranceStates.some((item) => item === '')

    if (isEmpty || personalInfoState.agree_to_assumption_terms === 'No' || personalInfoState.medical_wave_signature === 'No') {
      setInsuranceFieldError(true)
      setTimeout(() => setInsuranceFieldError(false), 1500)

    } else if (personalInfoState.ward_has_insurance === 'Yes' && personalInfoState.insurance_provider_name === '' && personalInfoState.insurance_provider_name === '') {
      setInsuranceFieldError(true)
      setTimeout(() => setInsuranceFieldError(false), 1500)
    } else {
      dispatch({ type: 'insurance', payload: false })
      dispatch({ type: 'codeofconduct', payload: true })
    }
  }


  const previousPageHandler = () => {

    dispatch({ type: 'insurance', payload: false })
    dispatch({ type: 'personalinfo', payload: true })

  }


  useEffect(() => {
    localStorage.setItem('assumptionChecked', assumptionChecked);
    const payload = assumptionChecked ? 'Yes' : 'No';
    personalInfoStateDispatch({ type: 'agree_to_assumption_terms', payload });
  }, [assumptionChecked, personalInfoStateDispatch]);


  useEffect(() => {
    localStorage.setItem('waiverChecked', medWaiverChecked);
    const payload = medWaiverChecked ? 'Yes' : 'No';
    personalInfoStateDispatch({ type: 'agree_to_medical_waiver_terms', payload });
  }, [medWaiverChecked, personalInfoStateDispatch]);


  useEffect(() => {
    if (nChecked || ycChecked) {
      personalInfoStateDispatch({ type: 'insurance_provider_name', payload: '' })
      personalInfoStateDispatch({ type: 'insurance_provider_phone', payload: '' })
    }
  }, [nChecked, ycChecked, personalInfoStateDispatch])


  const handleAssumptionChange = () => {
    setAssumptionChecked(!assumptionChecked);
  };


  const handleMedwaive = () => {
    setMedWaiverChecked(!medWaiverChecked)
  }


  return <>
    <ScrollToTop />
    <main className="mt-1  w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%]  ">
      <section className="mt-1 bg-white w-[100%] rounded-xl shadow-medium  ">
        <section className="px-5 py-5 small-pixel">
          <section>
            <h1 className="text-[#5030d2] herokid-bold font-bold text-[1.2rem]">Assumption of Risk, Waiver & Indemnity Agreement</h1>
            <section className='h-[30rem] overflow-x-hidden bg-[#F4F5F7] px-4'>
              {insurance.map((item) => <div className="py-4" key={item.title}>
                <h2 className="pb-1 herokid-regular text-[1.1rem] font-bold">{item.title}</h2>
                <p className='small_pixel text-[18px]'>
                  {item.text}
                </p>
              </div>)}
              <div className=''>
                <h2 className="pb-1 text-[1.1rem] herokid-regular font-bold">Clarifying Clauses:</h2>
                <ol className='list-decimal list-inside small_pixel text-[18px]'>
                  {
                    clause.map((clause) => <li className='py-2 ' key={clause}>{clause}</li>)
                  }
                </ol>
              </div>
            </section>
          </section>
          <section className="flex justify-between small-pixel py-4">
            <div className="w-[100%] flex items-center">
              <input type="checkbox" id='assumption_agreement'
                checked={assumptionChecked}
                onChange={handleAssumptionChange}
              />
              <label htmlFor="assumption_agreement" className="small_pixel text-[18px] px-2">I agree to all of the above terms and conditions.<sup className="text-[1rem]">*</sup> </label>
            </div>
          </section>
          <section className="small_pixel py-2">
            <div className="flex flex-col  w-[100%] ">
              <label htmlFor="assumption_sign" className="small_pixel text-[18px]">Assumption of Risk, Waiver & Indemnity E-Signature (Parent/Guardian type in full name):<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='assumption_sign' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.assumption_of_risk_signature} onChange={(e) => personalInfoStateDispatch({ type: 'assumption_of_risk_signature', payload: (e.target.value) })}
              />
            </div>
          </section>
          <section className='py-4'>
            <h2 className="text-[#5030d2] herokid-bold font-extrabold text-[1.2rem]">Medical Waiver</h2>
            <section className='h-[20rem] overflow-x-hidden bg-[#F4F5F7] px-4'>
              <ul className='list-disc list-inside small_pixel text-[18px]'>
                {medicalwaiver.map((item) => <li key={item} className='py-2'> {item}</li>)}
              </ul>
              <p className='py-2 small_pixel text-[18px]'>I CERTIFY THAT I HAVE READ AND COMPLETELY UNDERSTAND THE CONTENTS OF THIS DOCUMENT. I KNOW THIS IS A CONTRACT AND SIGN IT OF MY OWN FREE WILL</p>
            </section>
          </section>
          <section className="flex justify-between small-pixel py-4">
            <div className="w-[100%] flex items-center">
              <input type="checkbox" id='medical_agreement'
                checked={medWaiverChecked}
                onChange={handleMedwaive}
              />
              <label htmlFor="medical_agreement" className="small_pixel text-[18px] px-2">I agree to all of the above terms and conditions.<sup className="text-[1rem]">*</sup> </label>
            </div>
          </section>
          <section className="small_pixel py-2">
            <div className="flex flex-col  w-[100%] ">
              <label htmlFor="signature_medication" className="small_pixel text-[18px]">Medical Waiver E-Signature (Parent/Guardian type in full name):<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='signature_medication' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.medical_wave_signature} onChange={(e) => personalInfoStateDispatch({ type: 'medical_wave_signature', payload: (e.target.value) })}
              />
            </div>
          </section>
          <section className="flex flex-col small_pixel py-2 text-[18.5px]">
            <div className="  w-[100%]">
              <p className="">Does your ward have insurance?<sup className="text-[1rem]">*</sup>
              </p>
            </div>
            <section className=" w-[48%]  max-[624px]:w-[100%] small_pixel text-[18.5px] ">
              <section className="flex flex-row ">
                <div className="flex place-content-center items-center">
                  <input type="radio" id="waiver_yes" value='Yes'
                    checked={yChecked}
                    onChange={(e) => { setYchecked(true); setNchecked(false); setycChecked(false); personalInfoStateDispatch({ type: 'ward_has_insurance', payload: e.target.value }) }} />
                  <label htmlFor="waiver_yes" className="ml-2">Yes</label>
                </div>
                <div className="flex place-content-center items-center ml-[10%]">
                  <input type="radio" id="waiver_yescomp" value='Yes,but complete later'
                    checked={ycChecked}
                    onChange={(e) => { setycChecked(true); setYchecked(false); setNchecked(false); personalInfoStateDispatch({ type: 'ward_has_insurance', payload: e.target.value }) }} />
                  <label htmlFor="waiver_yescomp" className="ml-2">Yes, but complete later</label>
                </div>
                <div className="ml-[10%] flex place-content-center items-center">
                  <input type="radio" id="waiver_no" value='No'
                    checked={nChecked}
                    onChange={(e) => { setYchecked(false); setNchecked(true); setycChecked(false); personalInfoStateDispatch({ type: 'ward_has_insurance', payload: e.target.value }) }} />
                  <label htmlFor="waiver_no" className="ml-2">No</label>
                </div>
              </section>
            </section>
          </section>
          {yChecked && <>
            <section className=" small_pixel text-[18.5px] py-2">
              <div className="flex flex-col  w-[100%] py-2">
                <label htmlFor="provider_name" className="">Insurance Provider Name<sup className="text-[1rem]">*</sup></label>
                <input type="text" id='provider_name' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                  value={personalInfoState.insurance_provider_name} onChange={(e) => personalInfoStateDispatch({ type: 'insurance_provider_name', payload: (e.target.value) })}
                />
              </div>
            </section>
            <section className="small_pixel text-[18.5px] py-2">
              <div className="flex flex-col  w-[100%] py-2">
                <label htmlFor="provider_phone" className="">Insurance Provider Phone<sup className="text-[1rem]">*</sup></label>
                <input type="text" id='provider_phone' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                  value={personalInfoState.insurance_provider_phone} onChange={(e) => personalInfoStateDispatch({ type: 'insurance_provider_phone', payload: (e.target.value) })}
                />
              </div>
            </section>
          </>}
          {insuranceFieldError && <p className="py-5 text-center text-[red] small_pixel font-bold">all recommended fields (*) must be filled</p>}
          <div className="flex justify-between">

            <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel  cursor-pointer hover:bg-[blue]" onClick={previousPageHandler}>
              Previous
            </button>

            <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel  cursor-pointer hover:bg-[blue]" onClick={continuePageHandler}>
              continue
            </button>
          </div>
        </section>
      </section>
    </main>
  </>
}

export default Insurance;