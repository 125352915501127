
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataContext from "../../context/DataContext";
import { Bars } from 'react-loading-icons'
import api from '../../api/DataApi'


const RegisterButtonModal = () => {

   const [registrationCodeError, setRegistrationCodeError] = useState('')
    const [activeCodeError, setActiveCodeError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { setRegisterButtonModal, registrationCodeValue, setFullPaymentValue, payDispatch,
        setRegistrationCodeValue, registrationEmail, setRegistrationEmail,setRegUser, setPackageType,
    } = useContext(DataContext)


    const navigate = useNavigate()

    const validateRegisteredUser = async () => {

        setIsLoading(true)

        try {

            const validationPayload = {
                email: registrationEmail,
                token: registrationCodeValue,
            }

            const response = await api.post('validate', validationPayload)
            console.log(response)
            setRegUser(true)
            navigate('/payment')
            setIsLoading(false)
            setRegisterButtonModal(false)

            
            payDispatch({ type: 'firstName', payload: response.data.first_name })
            payDispatch({ type: 'lastName', payload: response.data.last_name })
            payDispatch({ type: 'phoneNumber', payload: response.data.phone_number })
            payDispatch({ type: 'email', payload: response.data.email })
            setFullPaymentValue(parseInt(response.data.total_amount_due))
            setPackageType(response.data.package_type)

            //clear defaults
            setRegistrationCodeValue('')
            setRegistrationEmail('')

        } catch (err) {
        
            if(err.response){
                setActiveCodeError(true)
                setIsLoading(false)
                setTimeout(() => setActiveCodeError(false), 1000)
            } else {
                setRegistrationCodeError('network error')
                setIsLoading(false)
                setTimeout(() => setRegistrationCodeError, 1000)
            }

        }
    }


    const regCodeHandler = () => {
            validateRegisteredUser()
    }


    return <>
        <div className="backdrop" onClick={() => setRegisterButtonModal(false)} ></div>

        <main className=" w-[32rem] max-[568px]:w-[100%] rounded-xl bg-white px-[1rem] modal-modal border border-3 border-[grey] fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
            <h2 className="text-center font-bold text-[1.3rem] herokid-bold font-extrabold text-[#5030d2]  pt-3 pb-1">Registration Options</h2>
            <div className=" pt-1">
                <ul className="list-disc list-inside">
                    <li className="small_pixel text-[17px] pb-2 " >
                        Yet to register?<span className="font-bold cursor-pointer text-[blue]" onClick={() => setRegisterButtonModal(false)}>
                            <Link to='/register'> CLICK HERE</Link></span> to proceed</li>
                    <li className="small_pixel text-[17px] " >Already registered? then enter your email and active registration code below to continue with payment</li>
                </ul>
            </div>
            {isLoading && <Bars stroke="grey" height={50} fill="grey"  className=" absolute left-[36%]"/>}
            <section className="small_pixel pt-4 ">
                <div className="flex flex-col ">
                    <label htmlFor="reg_payment_email" className="text-[#5030d2] herokid-bold font-extrabold text-[1.1rem] text-center">Email<sup className="text-[1.2rem]">*</sup></label>
                    <input type="email" id='reg_payment_email' className=" text-[16px] border border-[grey] rounded  
                    py-[0.15rem] px-2 outline-none" placeholder="enter the email associated with your registration"
                        value={registrationEmail}
                        onChange={(e) => setRegistrationEmail(e.target.value)}
                    />
                </div>
                <div className="flex flex-col pt-2 ">
                    <label htmlFor="reg_payment_option" className="text-[#5030d2] herokid-bold font-extrabold text-[1.1rem] text-center ">Registration Code<sup className="text-[1.2rem]">*</sup></label>
                    <input type="text" id='reg_payment_option' className=" text-[16px] border border-[grey] rounded  
                    py-[0.15rem] px-2 outline-none" placeholder="enter registration code"
                        value={registrationCodeValue}
                        onChange={(e) => setRegistrationCodeValue(e.target.value)}
                    />
                </div>
                {activeCodeError && <p className=" text-center text-[red] small_pixel font-bold text-[16px]">invalid credentials</p>}
                {registrationCodeError && <p className="py-5 text-center text-[red] small_pixel font-bold text-[16px]">{registrationCodeError}</p>}
                <div className="flex justify-center pt-[0.3rem]">
                    <button className="py-1 w-[40%]  bg-[#5030d2] rounded-xl text-white p-4 small_pixel font-bold cursor-pointer hover:bg-[blue]" onClick={regCodeHandler}>
                        Continue
                    </button>
                </div>
            </section>
        </main>
    </>
}

export default RegisterButtonModal;