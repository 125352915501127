
import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import RegisterPackage from "./choosePackageItems/RegisterPackage";
import DataContext from "../../../../context/DataContext";
import RegisterSuccessModal from "./RegisterSuccessModal";
import ScrollToTop from "../ScrollToTop";
import api from '../../../../api/DataApi';
import emailjs from '@emailjs/browser';
import { Bars } from 'react-loading-icons';



const ChoosePackage = () => {

  const [packageFieldError, setPackageFieldError] = useState(false)
  const [regError, setShowRegError] = useState(false)
  const { dispatch, personalInfoState, packageValue, packageType, payDispatch,
    showRegsuccess, setShowRegSuccess, setFullPaymentValue, setRegUser, register, setRegister, setISRegLoading, regErrorText, showRegerrorText } = useContext(DataContext)
  const [isLoading, setIsLoading] = useState(false)


  const navigate = useNavigate()

  const previousPageHandler = () => {

    dispatch({ type: 'choosepackage', payload: false })
    dispatch({ type: 'codeofconduct', payload: true })

  }

  // useEffect(() => {

  //   if (register) {
  //     console.log('it has started')
  //     const postRegister = async () => {

  //       setIsLoading(true)
  //       setISRegLoading(true)
  //       const reg_code = `${personalInfoState.first_name.split('').slice(0, 3).join('')}${Math.floor((Math.random() * (58944584947584 - 15674737457) + 45678))}POY2024`

  //       const regPayload = {

  //         //main person info
  //         first_name: personalInfoState.first_name,
  //         last_name: personalInfoState.last_name,
  //         gender: personalInfoState.gender,
  //         age_field: parseInt(personalInfoState.age),
  //         campus: personalInfoState.campus,
  //         shirt_size: personalInfoState.shirt_size,
  //         address: personalInfoState.address,
  //         city: personalInfoState.city,
  //         region: personalInfoState.region,
  //         zip_code: personalInfoState.zip_code,
  //         phone_number: personalInfoState.phone_number,
  //         email: personalInfoState.email,
  //         in_egroup: personalInfoState.in_egroup,
  //         days_of_week_grouping: personalInfoState.days_of_week_grouping,
  //         egroup_leader: personalInfoState.egroup_leader,
  //         friends_to_group_with: personalInfoState.friends_to_group_with,
  //         medication: personalInfoState.medication,
  //         allergies: personalInfoState.allergies,
  //         meal_plan: personalInfoState.meal_plan,
  //         ward_has_special_needs: personalInfoState.ward_has_special_needs,
  //         special_needs: personalInfoState.special_needs,
  //         wards_minor_injury_medication: personalInfoState.wards_minor_injury_medication,
  //         medications_consent: personalInfoState.medications_consent,
  //         package_type: packageType,

  //         //insurance
  //         agree_to_assumption_terms: personalInfoState.agree_to_assumption_terms,
  //         assumption_of_risk_signature: personalInfoState.assumption_of_risk_signature,
  //         agree_to_medical_waiver_terms: personalInfoState.agree_to_medical_waiver_terms,
  //         medical_wave_signature: personalInfoState.medical_wave_signature,
  //         ward_has_insurance: personalInfoState.ward_has_insurance,
  //         insurance_provider_name: personalInfoState.insurance_provider_name,
  //         insurance_provider_phone: personalInfoState.insurance_provider_phone,

  //         //code of conduct
  //         agree_to_code_of_conduct: personalInfoState.agree_to_code_of_conduct,
  //         ward_code_of_conduct_signature: personalInfoState.ward_code_of_conduct_signature,
  //         parent_code_of_conduct_signature: personalInfoState.parent_code_of_conduct_signature,
  //         below_eighteen_years: personalInfoState.below_eighteen_years,

  //         //Guardian Information
  //         guardian_first_name: personalInfoState.guardian_first_name,
  //         guardian_last_name: personalInfoState.guardian_last_name,
  //         guardian_phone_number: parseInt(personalInfoState.guardian_phone_number),
  //         guardian_email: personalInfoState.guardian_email,
  //         relation: personalInfoState.relation,
  //         relation_city: personalInfoState.relation_city,
  //         relation_region: personalInfoState.relation_region,
  //         relation_zip_code: personalInfoState.relation_zip_code,

  //         //active code
  //         token: reg_code,


  //         //total amount
  //         total_amount_due: parseInt(packageValue ? packageValue : 0)
  //       }

  //       try {

  //         await api.post('register', regPayload);

  //         setIsLoading(false)
  //         setISRegLoading(false)
  //         setFullPaymentValue(parseInt(packageValue ? packageValue : 0))

  //         setShowRegSuccess(true)
  //         setTimeout(() => { setShowRegSuccess(false); setRegUser(true); }, 3000) //navigate('/payment')

  //         payDispatch({ type: 'firstName', payload: personalInfoState.first_name })
  //         payDispatch({ type: 'lastName', payload: personalInfoState.last_name })
  //         payDispatch({ type: 'email', payload: personalInfoState.email })
  //         payDispatch({ type: 'phoneNumber', payload: personalInfoState.phone_number })

  //         emailjs.send('service_mqc4n3r', 'template_ujqbv5j', {
  //           subject: "Registration Confirmation and Payment Details",
  //           to_email: personalInfoState.email,
  //           gratitude_note: "Thank you for registering",
  //           gratitude_message: "We are excited to have you join us for the upcoming Power of You Festival hosted by Elevate Network",
  //           message: "The Power of You Festival is dedicated to harnessing the potential of Gen Z through Art, Entrepreneurship, and Technology. Join us at the La Palm Royal Beach Hotel in Accra for the dynamic Power of You Festival 2024, filled with invaluable networking opportunities, learning experiences, collaborative creation, and a celebration of talents all under one roof. Your unique registration code for payment purposes is provided below:",
  //           registration_code: `Registration Code : ${reg_code}`,
  //           total_amount_due: `Total Amount Due : GH₵${0}`,   //parseInt(packageValue ? packageValue : 0)
  //           amount_remaining: `Amount Remaining: GH₵${0}`, //parseInt(packageValue ? packageValue : 0)
  //           message_ending: "Mark your calendar for 9th October, 2024, to ensure you don't miss out on this incredible event. Prepare to take charge of your life, happiness, and well-being like a true CEO!",
  //           from_name: "POYF2024",
  //           subscription: "We eagerly anticipate your presence",
  //           team_name: "TEAM ELEVATE NETWORK",
  //         }, '5sgg7jOC3tZwFvABh')
  //           .then(function (response) {
  //             console.log("Email sent:", response);
  //           }, function (error) {
  //             console.error("Error sending email:", error);
  //           });

  //       } catch (err) {

  //         if (err.response) {

  //           console.log(err.response.data)
  //           console.log(err.response.status)
  //           console.log(err.response.headers)
  //           setIsLoading(false)
  //           setISRegLoading(false)
  //         }

  //         if (err.response.status === 409) {
  //           setIsLoading(false)
  //           setISRegLoading(false)
  //           showRegerrorText(err.response.data.error)

  //           setShowRegError(true)
  //           setTimeout(() => setShowRegError(false), 1000)
  //           setTimeout(() => showRegerrorText(""), 1000)

  //         }
  //       }

  //     }
  //     postRegister()
  //   }
  //   setRegister(false)
  // }, [register])




  const registerHandler = () => {

    if (packageValue === '' || packageType === '') {

      setPackageFieldError(true)
      setTimeout(() => setPackageFieldError(false), 1500)

    } else {

      // postRegister()

    }
  }

  return <>

    <ScrollToTop />
    <main className="mt-1  w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%] ">
      <section className="mt-1 bg-white w-[100%] rounded-xl shadow-medium  ">
        <section className="px-5 py-5 small-pixel">
          <section>
            <h1 className="text-[#5030d2] herokid-bold font-bold text-[1.5rem] pt-4 ">Choose Package Type<sup className="text-[1.2rem]">*</sup></h1>
            <RegisterPackage />
            {isLoading && <Bars stroke="grey" height={50} fill="grey" className=" absolute left-[46%]" />}
            <h1 className="text-[#5030d2] herokid-bold font-bold text-[1.5rem] pt-5">Summary</h1>
            <section className="py-2">
              <div className="w-[45%] max-[624px]:w-[100%]  flex justify-between pb-2">
                <p className="small_pixel text-[20px]"> Package Type: </p>
                <p className="small_pixel text-[20px]text-[#5030d2] text-[1.1rem]"> {packageType ? packageType : <span className="text-[red]">select package</span>}</p>
              </div>
              <div className="w-[45%] max-[624px]:w-[100%]  flex justify-between pb-2">
                <p className="small_pixel text-[20px]"> {`Total Cost(${1} * ${packageValue ? packageValue : 0}): `}</p>
                <p className="small_pixel text-[20px] text-[#5030d2]  text-[1.1rem] "> {`GH₵${1 * parseInt(packageValue ? packageValue : 0)}`}</p>
              </div>
            </section>
            <section>
              <h1 className="text-[#5030d2] herokid-bold font-bold text-[1.5rem] pt-4">Payment</h1>
              <p className="small_pixel text-[18px]">Full prepayment of conference fees is required to secure your Power of You Camp registrations.
                Until it is done the registration will not be officially confirmed. Payments are non-refundable and non-transferrable after the
                commencement of the program
              </p>
            </section>
            {/* <section>
              <p className="font-semibold pt-5">
                By checking the box below I acknowledge and consent that any personal data I enter in this
                form may be stored and processed by Elevate Network according to the terms of their <a href="###" className="text-[#5030d2] font-semibold">Privacy  Policy</a> and <a href="####" className="text-[#5030d2] font-semibold">Terms and Conditions</a>
              </p>
              <div className="w-[100%] flex items-center">
                <input type="checkbox" id='agreement' />
                <label htmlFor="agreement" className="font-semibold px-2">I agree to all of the above terms and conditions.<sup className="text-[1rem]">*</sup> </label>
              </div>
            </section> */}
            {packageFieldError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px]">Select a Package</p>}
            {regError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px]">{regErrorText}</p>}
            <div className="flex justify-between pt-4">
              <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18.5px] cursor-pointer hover:bg-[blue]" onClick={previousPageHandler} >
                Previous
              </button>
              <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18.5px] cursor-pointer hover:bg-[blue]"
                onClick={registerHandler}
              >
                Register
              </button>
            </div>
          </section>
        </section>
      </section>
    </main>
    {showRegsuccess && <RegisterSuccessModal />}

  </>
}

export default ChoosePackage;