import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import '../src/fonts/Swell/Swell-Regular.otf';
import '../src/fonts/recommended/HEROKID-BOLD.OTF';
import '../src/fonts/recommended/HEROKID-REGULARNARROW.OTF';
import '../src/fonts/recommended/small_pixel-7.ttf';
import '../src/fonts/recommended/HEROKID-BOLDEXPANDED.OTF'
import './index.css';
import ScrollToTop from './components/mainbody/mainbodyItems/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

