

import { useState, useContext } from 'react';
import DataContext from '../../../../../context/DataContext';


const RegisterPackage = () => {

    const [goldenBorder, setGoldenBorder] = useState('')
    const [platinumBorder, setPlatinumBorder] = useState('')
    const [generalBorder, setGeneralBorder] = useState('')

    const { setPackageValue, setPackageType } = useContext(DataContext)



    return <>

        <section className='container py-4 max-[1326px]:flex max-[1326px]:place-content-center'>

            <section className='min-[1326px]:flex min-[1326px]:place-content-center max-[360px]:ml-[20%]  '>
                <section className={`${generalBorder}  w-[20rem]  bg-white h-[25rem]  shadow-xlarge cursor-pointer
                        rounded-xl lg:mr-2 `} onClick={() => { setPackageValue(900); setPackageType('General Access'); setGoldenBorder(''); setPlatinumBorder('');setGeneralBorder('border-4 border-[yellow]') }}>
                    <div className=' text-center herokid-regular font-bold border-[#002275] border-b-2'>
                        <h1 className='text-[#002275] text-[1.8rem]'>General Access</h1>
                    </div>
                    <div className='text-[#002275] text-[1.3rem] px-2 herokid-regular font-bold'>
                        <p className='text-center text-[#002275] cursor-pointer' >Thorough</p>
                        <p className='text-center'>Broad Experience</p>
                    </div>
                    <div className=''>
                        <h2 className='mt-[4rem] text-[2.5rem] text-[#002275] herokid-bold font-bold text-center flex place-content-center'>GH₵900</h2>
                    </div>
                    <div style={{ marginTop: '15%' }}></div>
                    <p className='small_pixel text-[18.5px] tracking-[0.2rem] text-[1rem] text-center'>please click to select package</p>
                
                </section>
                <section className={`${goldenBorder}  w-[20rem]  bg-white h-[25rem] max-[1326px]:mt-4 max-[560px]:mt-4  shadow-xlarge cursor-pointer
                        rounded-xl lg:mr-2 `} onClick={() => { setPackageValue(1500); setPackageType('Golden Access'); setGoldenBorder('border-4 border-[yellow]'); setPlatinumBorder('');setGeneralBorder('') }}>
                    <div className=' text-center herokid-regular font-bold border-[#002275] border-b-2'>
                        <h1 className='text-[#002275] text-[1.8rem]'>Golden Access</h1>
                    </div>
                    <div className='text-[#002275] text-[1.3rem] px-2 herokid-regular font-bold'>
                        <p className='text-center text-[#002275] cursor-pointer' >Comprehensive</p>
                        <p className='text-center'>Golden Experience</p>
                    </div>
                    <div className=''>
                        <h2 className='mt-[4rem] text-[2.5rem] text-[#002275] herokid-bold font-bold text-center flex place-content-center'>GH₵1500</h2>
                    </div>
                    <div style={{ marginTop: '15%' }}></div>
                    <p className='small_pixel text-[18.5px] tracking-[0.2rem] text-[1rem] text-center'>please click to select package</p>
                
                </section>

                <section className={`${platinumBorder} cursor-pointer w-[20rem] h-[25rem] max-[1326px]:mt-4 max-[560px]:mt-4 bg-white shadow-xlarge rounded-xl `} onClick={() => { setPackageValue(2000); setPackageType('Platinum Access'); setGoldenBorder(''); setPlatinumBorder('border-4 border-[yellow]');setGeneralBorder('') }}>
                    <div className=' text-center herokid-regular font-bold border-[#002275] border-b-2'>
                        <h1 className='text-[#002275] text-[1.8rem]'>Platinum Access</h1>
                    </div>
                    <div className='text-[#002275] text-[1.3rem] px-2 herokid-regular font-bold'>
                        <p className='text-center text-[#002275] cursor-pointer' >Complete</p>
                        <p className='text-center'>Exclusive Service</p>
                    </div>
                    <div className=''>
                        <h2 className='text-[#002275] mt-[4rem] text-[2.5rem] herokid-bold font-bold text-center flex place-content-center'>GH₵2000</h2>
                    </div>
                    <div style={{ marginTop: '15%' }}></div>
                    <p className='small_pixel text-[18.5px]  tracking-[0.2rem] text-[1rem] text-center '>please click to select package</p>
                </section>

            </section>

        </section>
    </>
}

export default RegisterPackage;