import { useContext } from 'react'
import { RiTwitterXFill } from 'react-icons/ri'
import { TfiInstagram } from 'react-icons/tfi'
import { LiaLinkedinIn } from 'react-icons/lia'
import { Link } from 'react-router-dom'
import DataContext from '../../../context/DataContext'


const Footer = () => {

    const {setShowContactUs} = useContext(DataContext)

    return (<>
        <main className="bg-[#5030d2] py-[5rem]">
            <div className="container flex justify-between items-center max-[651px]:flex max-[651px]:flex-col">
                <section className=''>
                    <div className='text-white text-[2rem] herokid-bold font-bold  max-[651px]:text-center' >
                        Power of You Festival
                    </div>
                    <p className='w-[60%] max-[770px]:w-[100%] mt-4 text-[20px] herokid-regular  text-white max-[651px]:text-center'>Come, Seek, for  Searching is the foundation of fortune</p>
                    <p className=" text-[1.2rem] mt-4 text-[white] herokid-regular max-[651px]:hidden ">Copyright &copy; 2024. All Rights Reserved By Power of You</p>
                </section>

                <section className=' pl-2 max-[299px]:pl-0  max-[651px]:mt-[2rem] '>
                    <Link to="/scholarship"><p className='pb-5 text-[1.2rem] text-white herokid-bold font-bold cursor-pointer max-[651px]:text-center'>SCHOLARSHIPS</p></Link>
                    <p className='pb-5 text-[1.2rem] text-white herokid-bold font-bold cursor-pointer max-[651px]:text-center' onClick={()=>setShowContactUs(true)}>CONTACT US</p>
                    <div className='flex content-between '>
                        {/* <a href='###' className='p-nav p-3 rounded-full ' ><FiFacebook color='white' /></a> */}
                        <a href='https://twitter.com/elevatenetwork1' target='blank' className=' p-nav p-3  rounded-full'><RiTwitterXFill color='white' /></a>
                        <a href='https://www.instagram.com/elevatenetworkhq' target='blank' className='ml-[4rem]  max-[299px]:ml-4 p-nav p-3 rounded-full'><TfiInstagram color='white' /></a>
                        <a href='https://www.linkedin.com/company/elevatenetwork/' target='blank' className='ml-[4rem]  max-[299px]:ml-4 p-nav p-3 rounded-full'><LiaLinkedinIn color='white' /></a>
                    </div>
                </section>
                <p className=" text-[1.2rem] mt-[2rem] text-[white] herokid-regular min-[651px]:hidden text-center ">Copyright &copy; 2024. All Rights Reserved By Power of You</p>
            </div>
        </main>
    </>)
}

export default Footer;