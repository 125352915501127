
import { Helmet } from 'react-helmet-async';
//import memo from 'react';
import { useInView } from 'react-intersection-observer';
import powervid2 from '../../../videos/backlogVideo.mp4';
//import footervideo from '../../../videos/footerVideo.mp4';
import powerlogo from '../../../images/POYF-03.png';
import ImageViewer from "./HomeItems/ImageViewer";
import CampExperience from './HomeItems/CampExperience';
import DesireofFuture from "./HomeItems/DesireofFuture";
import Packages from './HomeItems/Packages';
import Programme from '../mainbodyItems/Programme';
import FAQ from '../mainbodyItems/FAQ';
import TargetAudience from './HomeItems/TargetAudience';
import Guest from './HomeItems/Guests';



const Home = () => {

    const [ref, inView] = useInView({

    });

    return <>
        <Helmet>
            <title>Power of You 2024 - About</title>
            <meta name='description' content='searching is the foundation of fortune, come and seek.' />
            <link rel="canonical" href="https://powerofyoufestival.com/" />
        </Helmet>

        <main className='mt-[6rem] max-[1020px]:mt-[6rem] overflow-x-hidden flex-grow' >
            <section className=" bg-[#5030d2] h-[52%] pb-[2rem]  " ref={ref}>
                <div className={`container transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                    }`}
                >
                    <div>
                        <div className=' min-[560px]:flex min-[560px]:flex-row min-[1024px]:word-space text-[#deec1d] text-[7rem] 
                        max-[1285px]:text-[5.5rem] max-[1024px]:text-[4rem] max-[770px]:text-[3rem] max-[584px]:text-[2.5rem] max-[590px]:mt-[0.5rem] max-[401px]:whitespace-nowrap herokid-bold font-bold' >
                            Power of You <p className='max-[559px]:mt-[-1rem] max-[401px]:mt-[-1rem] min-[560px]:ml-3'>Festival</p>
                        </div>
                        <div className='absolute   text-[#deec1d] text-[1.5rem] max-[559px]:mt-[-0.1rem] max-[1024px]:mt-[0.1rem]
                        max-[1285px]:text-[1.2rem] max-[1024px]:text-[1.2rem] max-[770px]:text-[1rem] max-[584px]:text-[1rem] max-[352px]:text-[0.7rem] mt-[-1.5rem] max-[401px]:whitespace-nowrap herokid-regular font-semibold ml-2'>
                            <p>Celebrating International Day of the Girl Child</p>
                            <p className='mt-[1.1rem]'>9th - 13th October, 2024</p>
                        </div>
                    </div>
                </div>
                <div className='container mt-[-5.2rem] max-[1024px]:mt-[-2.5rem] max-[559px]:mt-[-1.5rem]'>
                    <div className='border-4 border-[#5030d2] w-[full] '>
                        <video autoPlay muted loop className=' h-[35rem] max-[630px]:h-[20rem]  w-full object-cover rounded-lg ' loading='lazy' >
                            <source src={powervid2} type="video/mp4" data-wf-ignore="true" className='rounded-lg' />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className='container py-1' >
                    <p className={`w-[60%] mt-5 max-[770px]:w-[100%] text-white text-[20px] small_pixel transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                        } `}>
                        The Power of You Festival is a four-day enrichment program designed for basic, high school and university students between
                        the ages of 11 and 22. Power of You Festival broadens members' visions and goals, as well as motivates,
                        challenges and prepares them with skills for the future of work and career.
                    </p>
                    <p className={`w-[60%] max-[770px]:w-[100%] mt-5 text-white text-[20px] small_pixel transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                        } `}>
                        Leadership, Agriculture, Design, Entrepreneurship, Robotics, Creative Arts and Engineering are among
                        the talents taught in the program.
                    </p>
                    <p className={`w-[60%] max-[770px]:w-[100%] mt-5 text-white text-[20px] small_pixel transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                        } `}>
                        Featuring world-class speakers, ecosystem
                        socials, opportunities to grow, and a space to find your people.
                    </p>
                    <p className='w-[60%] max-[770px]:w-[100%] text-[24px] small_pixel font-bold text-[#deec1d] mt-5'>Come, Seek, for  Searching is the foundation of fortune</p>
                    {/* <h1 className='swell-Regular text-[40px] max-[592px]:text-[30px] max-[450px]:text-[25px] text-[#deec1d] '>University of Ghana,Legon,Ghana</h1> */}
                </div>
            </section>
            <img src={powerlogo} alt="" width={300} className='absolute bottom-[3.5%] right-0 max-[770px]:hidden' />
            <DesireofFuture />
            <TargetAudience />
            <Guest />
            <CampExperience />
            <ImageViewer />
            <section id='packages'>
                <Packages />
            </section>
            <section id='programmeDetails'>
                <Programme />
            </section>

            <section id='faq' className='faq py-[5rem] flex justify-between items-center relative max-[800px]:flex max-[800px]:flex-col'>
                <h1 className="container text-[white] text-center herokid-bold   text-[20rem] max-[1115px]:text-[15rem] max-[887px]:text-[10rem] max-[800px]:text-center ">
                    FAQ
                </h1>
                <FAQ />
            </section>

            <section id='about' className='bg-[#f1fcff] pt-[2rem] pb-[3.5rem] flex justify-between items-center relative max-[800px]:flex max-[800px]:flex-col'>
                <div className='container'>
                    <h1 className='text-[3.5rem] md:text-[5rem]  text-black  border-black herokid-bold font-bold border-b-4'>About Us</h1>
                    <div className={`container flex justify-between max-[1024px]:flex max-[1024px]:flex-col `}>
                        <section className={`w-[43.5rem]  max-[1024px]:w-[100%] mt-[2rem] `}>
                            {/* <h1 className='text-[3rem] swell-Regular pb-[1rem]  max-[1024px]:text-[2.5rem]'>Event Sessions</h1> */}
                            <p className="leading-[2rem] small_pixel text-[20px]">
                                Elevate Network has kept true to its mandate to transform economic value through innovation and creativity. The mission
                                for Elevate Network has remained the same: building a high economy and social impact imbued with inclusiveness, innovation,
                                and creativity.

                                During this time, we have laid a solid foundation by igniting and nurturing a community of startups, entrepreneurs, investors, and

                            </p>
                        </section>

                        <section className={`w-[43.5rem]  max-[1024px]:w-[100%] mt-[2rem] `}>
                            {/* <h1 className='text-[3rem] swell-Regular pb-[1rem]  max-[1024px]:text-[2.5rem]'>Platinum Tickets Parties</h1> */}
                            <p className="leading-[2rem] small_pixel text-[20px]">

                                ecosystem builders while simultaneously accelerating towards a world of new discoveries where
                                thriving and sustainable businesses are propelled into a greater future. We are in a great position today
                                to continue creating impact as an organization by connecting the startup ecosystem to greater opportunities by
                                expanding and extending their growth.
                            </p>
                        </section>

                    </div>
                </div>

            </section>

            <div className='relative'>
                <div className=' w-screen'>
                    <video autoPlay muted loop className=' h-[38rem] max-[630px]:h-[20rem]  w-full object-cover ' loading='lazy' >
                        <source src={powervid2} type="video/mp4" data-wf-ignore="true" />
                        Your browser does not support the video tag.
                    </video>
                    <div className='flex place-content-center'>
                        <img src={powerlogo} alt="logo.webp" className=' w-[20%] absolute bottom-[10rem]  max-[630px]:absolute max-[630px]:bottom-[5rem] max-[800px]:bottom-[10rem]' />
                    </div>
                </div>
            </div>
        </main>
    </>
}

export default Home;

//#f1fcff azure for next background