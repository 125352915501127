
import { useContext, useState } from "react";
import { Helmet } from 'react-helmet-async';
import PaystackPop from '@paystack/inline-js';
import DataContext from "../../../../context/DataContext";
import api from '../../../../api/DataApi'
import emailjs from '@emailjs/browser';

const GiftPayment = () => {

    const { giftState, giftDispatch } = useContext(DataContext)
    const [fieldError, setFieldError] = useState(false)

    const publicKey = "pk_live_e55956868b5534b12ac9e070900fd6acf504c977"


    const donateGift = async () => {

        const giftpayload = {
            first_name: giftState.firstName,
            last_name: giftState.lastName,
            phone_number: giftState.phoneNumber,
            email: giftState.email,
            amount: parseInt(giftState.amount)
        }

        console.log(giftpayload)

        try {

            await api.post('gifts', giftpayload)

            emailjs.send('service_mqc4n3r', 'template_ujqbv5j', {
                subject: " Thank You for Your Generous Scholarship Contribution!",
                to_email: giftState.email,
                gratitude_message: "We are truly grateful for your commitment to fostering learning and empowering the next generation. Your donation embodies a belief in the potential of others and represents a significant step in creating a brighter, more educated future.",
                message: "Thank you once again for your invaluable support and belief in our cause. Your generosity will create lasting ripples of positivity in the lives of those who receive this scholarship gift.",
                subscription: "Warm regards",
                team_name: "TEAM ELEVATE NETWORK",
            }, '5sgg7jOC3tZwFvABh')
                .then(function (response) {
                    console.log("Email sent:", response);
                }, function (error) {
                    console.error("Error sending email:", error);
                });

        } catch (err) {
            console.log(err.response.data)
            console.log(err.response.status)
            console.log(err.response.headers)
        }

    }


    const payFormHandler = (e) => {

        e.preventDefault()

        if (giftState.firstName === '' || giftState.lastName === '' || giftState.email === '' || giftState.amount === '' || giftState.phoneNumber === '') {

            setFieldError(true)

            setTimeout(() => setFieldError(false), 1500)

        } else {

            const paystack = new PaystackPop()

            paystack.newTransaction({
                key: publicKey,
                amount: parseInt(giftState.amount * 100),
                email: giftState.email,
                firstname: giftState.firstName,
                lastname: giftState.lastName,
                phone: giftState.phoneNumber,


                onSuccess(transaction) {

                    donateGift()

                    alert(`Payment Complete Reference ${transaction.reference}  Thank you for helping a ward attend this event`)

                    giftDispatch({ type: 'firstName', payload: '' })
                    giftDispatch({ type: 'lastName', payload: '' })
                    giftDispatch({ type: 'phoneNumber', payload: '' })
                    giftDispatch({ type: 'email', payload: '' })
                    giftDispatch({ type: 'amount', payload: '' })
                },

                onCancel() {
                    alert('transaction cancelled')
                }

            })
        }

    }

    return (<>

        <Helmet>
            <title>Power of You 2024 - Gift</title>
            <meta name='description' content='gift a scholarship' />
            <link rel="canonical" href="https://powerofyoufestival.com/gift" />
        </Helmet>

        <main className="mt-[6rem] flex-grow">
            <section className="background-element h-[28.5rem] ">
                <div className="container  h-[100%]  flex items-center place-content-center ">
                    <div className="text-white text-center">
                        <h1 className="herokid-bold font-bold text-center text-white  text-[4rem] max-[772px]:text-[3rem] leading-[3.5rem]">SCHOLARSHIP DONATIONS</h1>
                        <p className="small_pixel  text-[2.5rem] max-[772px]:text-[1rem]">POWER OF YOU FESTIVAL 2024</p>
                        <div className="container flex flex-col place-content-center items-center ">
                            <p className="text-center pt-[1.5rem] pb-[1rem]   w-[50%] max-[772px]:w-[100%] small_pixel text-[1.2rem] max-[526px]:text-[0.9rem] ">
                                By contributing, you become the solution for 11-22-year-olds to attend the event, for which we'd be immensely thankful.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <h1 className="text-[#5030d2] herokid-bold font-bold text-[2rem] pt-5">PAYMENT DETAILS</h1>
                <section className=" flex place-content-center pt-[3rem] pb-[8rem]">
                    <form className="w-[60%] relative" >
                        {fieldError && <p className="py-5 text-center text-[red] small_pixel font-bold">all fields must be filled</p>}
                        <section className="small_pixel min-[624px]:flex min-[624px]:justify-between">
                            <div className="flex flex-col w-[48%] max-[624px]:w-[100%] ">
                                <label htmlFor="gift_firstName" className="text-[18.5px]">First Name<sup className="text-[1rem]">*</sup></label>
                                <input type="text" id='gift_firstName' className=" border-b-2 border-b-[grey] 
                                bg-[#f1f1f1] outline-none bg- py-[0.15rem] px-2"
                                    value={giftState.firstName} onChange={(e) => giftDispatch({ type: 'firstName', payload: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%] max-[624px]:pt-3 ">
                                <label htmlFor="gift_lastName" className="text-[18.5px]">Last Name<sup className="text-[1rem]">*</sup></label>
                                <input type="text" id='gift_lastName' className="border-b-2 border-b-[grey] bg-[#f1f1f1] 
                                outline-none py-[0.15rem] px-2"
                                    value={giftState.lastName} onChange={(e) => giftDispatch({ type: 'lastName', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <section className="min-[624px]:flex min-[624px]:justify-between small_pixel pt-[2.5rem]">
                            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
                                <label htmlFor="gift_telnumber" className="text-[18.5px]">Phone Number<sup className="text-[1rem]">*</sup></label>
                                <input type="tel" id='gift_telnumber' className="border-b-2 border-b-[grey] 
                                bg-[#f1f1f1] py-[0.15rem] outline-none px-2"
                                    value={giftState.phoneNumber} onChange={(e) => giftDispatch({ type: 'phoneNumber', payload: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
                                <label htmlFor="gift_email" className="text-[18.5px]">Email<sup className="text-[1rem]">*</sup></label>
                                <input type="email" id='gift_email' className="border-b-2 border-b-[grey] bg-[#f1f1f1] outline-none py-[0.15rem] px-2" autoComplete="on"
                                    value={giftState.email} onChange={(e) => giftDispatch({ type: 'email', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <section className="small_pixel pt-[2.5rem]">
                            <div className="flex flex-col  w-[100%] py-2">
                                <label htmlFor="gift_amount" className="text-[18.5px]">Amount(GH₵)<sup className="text-[1rem]">*</sup></label>
                                <input type="number" id='gift_amount' className="border-b-2 border-b-[grey]
                                 bg-[#f1f1f1] outline-none py-[0.15rem] px-2"
                                    value={giftState.amount} onChange={(e) => giftDispatch({ type: 'amount', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <div className="flex justify-center pt-[2.5rem]">

                            <button className="py-2 w-[10rem]  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[20px] cursor-pointer hover:bg-[blue]"
                                onClick={payFormHandler}
                            >
                                Review and Pay
                            </button>
                        </div>
                    </form>
                </section>
            </section>
        </main>
    </>
    )
}

export default GiftPayment;