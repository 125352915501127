
import { useInView } from 'react-intersection-observer';
import schedule from '../../../scheduleData/schedule.json';


const Programme = () => {

    const [ref, inView] = useInView({

    });

    return <>

        <main className="bg-[#f1fcff]">
            <section className="container pt-[2.5rem]">
                <section className="flex justify-between max-[1024px]:flex max-[1024px]:flex-col">
                    <section className={``}>
                        <div className=' w-full flex justify-between border-[black] border-b-4 '>
                            <h1 className="herokid-bold font-bold text-[black] text-[2rem]  max-[1025px]:text-[1.5rem] max-[401px]:text-[0.8rem] max-[770px]:hidden"> Venues </h1>
                            <h1 className="herokid-bold font-bold text-[black] text-[2rem]  max-[1025px]:text-[1.5rem] max-[401px]:text-[0.8rem]"> Anagkazo  || Papaye Recreational Village </h1>
                        </div>
                        <p className="leading-[2rem] text-[20px] small_pixel py-4">
                            <span className='font-bold'>Anagkazo,</span>located in Mampong, is nestled amidst
                            the picturesque Akuapim mountains, just 38 km from Shai Hills Resource Reserve and 40 km from Wheel Story House.
                            Conveniently situated, it is within 45 km of Kwame Nkrumah Memorial Park and Independence Arch. It offers a serene and tranquil environment ideal for gatherings. 
                            Explore further and start planning your trip today.
                        </p>
                        <p className="leading-[2rem] text-[20px] small_pixel py-4">
                            <span className='font-bold'> Papaye Recreational Village </span>is a subsidiary of the Papaye Group of Companies, which is located on a 120-acre land in 
                            Konkonuro, Eastern Region of Ghana. It is a place that provides a serene environment for leisure and relaxation, with event centre.
                        </p>
                    </section>
                </section>
                <section>
                    <div className=' border-[black] border-t-4 w-full flex justify-between pt-[1.5rem]'>
                        <h1 className="herokid-regular text-[black] md:text-[1.8rem]  max-[1025px]:text-[1.5rem] max-[401px]:text-[1rem]"> Schedule </h1>
                        <h1 className="herokid-regular text-[black] md:text-[1.8rem]  max-[1025px]:text-[1.5rem] max-[401px]:text-[1rem]">9th-13th October,2024</h1>
                    </div>
                    <section className='flex lg:flex-row place-content-center pb-[4rem] max-[1024px]:flex-col items-center ' ref={ref}>
                        {schedule.map((item) => <div key={item.day} className='px-4 small_pixel  border mx-2 w-[20rem] bg-[#5030d2] h-[20rem] rounded-xl'>
                            <h1 className='text-center text-white  border-[white] border-b-2 py-2 font-bold text-[1.5rem] '>{item.day}</h1>
                            <div className={`pt-[1rem] transition-transform duration-3000 transform ${inView ? '-translate-x-0' : 'translate-x-full'}`}>
                                <div className='flex justify-between text-white text-[20px]'>
                                    <p>9:30am</p>
                                    <p>{item.session1}</p>
                                </div>
                                <div className='flex justify-between text-white text-[20px] pt-[1rem]'>
                                    <p>10:15am</p>
                                    <p>{item.session2}</p>
                                </div>
                                <div className='flex justify-between text-white text-[20px] pt-[1rem]'>
                                    <p></p>
                                    <p>{item.free_time}</p>
                                </div>
                                <div className='flex justify-between text-white text-[20px] pt-[1rem]'>
                                    <p>7:00pm</p>
                                    <p>{item.session3}</p>
                                </div>
                                <div className='flex justify-between text-white text-[20px] pt-[1rem]'>
                                    <p>9:15pm</p>
                                    <p>{item.session4}</p>
                                </div>
                            </div>
                        </div>)}
                    </section>
                </section>
            </section>
        </main>

    </>
}

export default Programme;