
import { useContext, useRef, useState } from "react";
import DataContext from "../../context/DataContext";
import emailjs from '@emailjs/browser';
import { Bars } from 'react-loading-icons'

const ContactUsModal = () => {

    const [messageError, setMessageError] = useState(false)
    const [messageFieldError, setMessageFieldError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { setShowContactUs, contactState, contactDispatch, setShowEmailSuccess } = useContext(DataContext)



    const form = useRef()

    const messageStates = [contactState.firstName,
    contactState.lastName,
    contactState.email,
    contactState.phoneNumber,
    contactState.message]

    const submitEmail = (e) => {

        e.preventDefault()

        const isEmpty = messageStates.some((item) => item === '')

        if (isEmpty) {
            setMessageFieldError(true)
            setTimeout(() => setMessageFieldError(false), 1500)

        } else {
            setIsLoading(true)
            emailjs.sendForm('service_mqc4n3r', 'template_0y29nzq', form.current, '5sgg7jOC3tZwFvABh')
                    
                .then((result) => {
                    console.log(result.text);
                    setIsLoading(false)
                    setShowContactUs(false)
                    setShowEmailSuccess(true)
                    setTimeout(() => setShowEmailSuccess(false), 1000)

                    //clear defaults

                    contactDispatch({ type: 'firstName', payload: '' })
                    contactDispatch({ type: 'lastName', payload: ''})
                    contactDispatch({ type: 'phoneNumber', payload: '' })
                    contactDispatch({ type: 'email', payload: ''})
                    contactDispatch({ type: 'subject', payload: '' })
                    contactDispatch({ type: 'message', payload: ''})

                }, (error) => {
                    console.log(error.text);
                    setShowContactUs(true)
                    setMessageError(true)
                    setTimeout(() => setMessageError(false), 1500)
                });
        }



    }

    return <>
        <div className="backdrop" onClick={() => setShowContactUs(false)}></div>
        <main className=" w-[32rem] max-[568px]:w-[100%] rounded-xl bg-white px-[1rem] modal-modal border border-3 border-[grey] fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
            <h2 className="text-center herokid-regular text-[1.05rem] pt-3">Fill out the form below and our team will get in touch</h2>
            {messageError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px] font-semibold text-sm">message failed to go through, try again or check network</p>}
            {messageFieldError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px] font-semibold text-sm">all recommended fields (*) must be filled</p>}
            <div>
                <form className=" relative py-4 px-[2rem]" onSubmit={submitEmail} ref={form} >
                    <section className="small_pixel text-[18.5px] min-[624px]:flex min-[624px]:justify-between">
                        <div className="flex flex-col w-[48%] max-[624px]:w-[100%] ">
                            <label htmlFor="contact_firstName" className="text-[18.5px]">First Name<sup className="text-[1rem]">*</sup></label>
                            <input type="text" id='contact_firstName' name="first_name" className=" border-b-2 border-b-[grey] text-[1.1rem] outline-none px-2"
                                value={contactState.firstName} onChange={(e) => contactDispatch({ type: 'firstName', payload: e.target.value })}
                            />
                        </div>
                        <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%] max-[624px]:pt-3 ">
                            <label htmlFor="contact_lastName" className="text-[18.5px]">Last Name<sup className="text-[1rem]">*</sup></label>
                            <input type="text" id='contact_lastName' name="last_name" className="border-b-2 border-b-[grey] text-[1.1rem]  
                                outline-none px-2 "
                                value={contactState.lastName} onChange={(e) => contactDispatch({ type: 'lastName', payload: e.target.value })}
                            />
                        </div>
                    </section>
                    <section className="min-[624px]:flex min-[624px]:justify-between small_pixel text-[18.5px] pt-[1rem]">
                        <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
                            <label htmlFor="contact_telnumber" className="text-[18.5px]">Phone Number<sup className="text-[1rem]">*</sup></label>
                            <input type="tel" id='contact_telnumber' name="user_phone" className="border-b-2 border-b-[grey] text-[1.1rem] 
                                   outline-none px-2"
                                value={contactState.phoneNumber} onChange={(e) => contactDispatch({ type: 'phoneNumber', payload: e.target.value })}
                            />
                        </div>
                        <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
                            <label htmlFor="contact_email" className="text-[18.5px]">Email<sup className="text-[1rem]">*</sup></label>
                            <input type="email" id='contact_email' name="user_email" className="border-b-2 border-b-[grey] outline-none  px-2 text-[1.1rem]" autoComplete="on"
                                value={contactState.email} onChange={(e) => contactDispatch({ type: 'email', payload: e.target.value })}
                            />
                        </div>
                    </section>
                    {isLoading && <Bars stroke="grey" height={50} fill="grey"  className=" absolute left-[36%]"/>}
                    <section className="small_pixel text-[18.5px] pt-[1rem]">
                        <div className="flex flex-col  w-[100%] py-2">
                            <label htmlFor="subject" className="text-[18.5px]">Subject<sup className="text-[1rem]">*</sup></label>
                            <input type="text" id='subject' name="user_subject" className="border-b-2 border-b-[grey] text-[1.1rem]
                                  outline-none  px-2 "
                                value={contactState.subject} onChange={(e) => contactDispatch({ type: 'subject', payload: e.target.value })}
                            />
                        </div>
                    </section>
                    <section className="small_pixel text-[18.5px] pt-[1rem]">
                        <div className="flex flex-col  w-[100%] py-2">
                            <label htmlFor="message" className="text-[18.5px]">Message<sup className="text-[1rem]">*</sup></label>
                            <textarea id='message' name="message" className="border border-[grey] border-b-2 border-b-[grey] outline-none  px-2 rounded-lg text-[1.1rem] h-[5rem]"
                                value={contactState.message} onChange={(e) => contactDispatch({ type: 'message', payload: e.target.value })}
                            />
                        </div>
                    </section>
                    <div className="flex justify-center pt-[1.5rem]">
                        <button className="py-2 w-[40%]  bg-[#5030d2] rounded-xl text-white 
                            p-4 small_pixel text-[18.5px] cursor-pointer hover:bg-[blue]" >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </main>
    </>
}


export default ContactUsModal;