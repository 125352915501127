
import { useState, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";


const Navigations = () => {

    const [showHome, setShowHome] = useState(true)
    const [showRegister, setShowRegister] = useState(false)
    const [showPayment, setShowPayment] = useState(false)
    const [registerBorderB, setRegisterBorderB] = useState()
    const [showScholarship, setShowScholarship] = useState(false)

     const location = useLocation()


    useEffect(() => {

        if (location.pathname === '/') {
            setShowHome(true)
              setShowRegister(false)
              setShowPayment(false)
        }
       
         if (location.pathname === '/register' || location.pathname === '/payment' || location.pathname === '/scholarship' || location.pathname === '/scholarshipform' || location.pathname === '/gift' ) {
        
            setRegisterBorderB('border-b-2')
        }
       

        if (location.pathname === '/register') {
              setShowHome(false)
              setShowRegister(true)
              setShowPayment(false)
            } else{
                setShowRegister(false)
            }

        if (location.pathname === '/scholarship' || location.pathname === '/scholarshipform' || location.pathname === '/gift') {
              setShowHome(false)
              setShowRegister(false)
              setShowPayment(false)
              setShowScholarship(true)
            } else{
                setShowScholarship(false)
            }

        if (location.pathname === '/payment') {
              setShowHome(false)
              setShowRegister(false)
              setShowPayment(true)
            } 

        
     }, [location.pathname])


    return (

        <main className='flex items-center place-content-center max-[1024px]:hidden' >

            <section className="flex justify-between herokid-regular tracking-[2px] text-[24px] font-bold 
            cursor-pointer text-[#deec1d]">

                <Link to='/'>
                    <p className={`py-1 transition ease-in-out delay-150 
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2]`}>
                        About</p>
                </Link>

              { showHome && <>
                    <a href='#packages'><p className={`ml-[4rem] py-1  transition ease-in-out delay-150  
                    hover:-translate-y-1 hover:scale-110 duration-300 text-[#deec1d] border-[#c5c500]  hover:text-[#ff30d2]`} >Packages</p>
                    </a>
                    <a href='#programmeDetails'>
                        <p className={`ml-[4rem] py-1  transition ease-in-out delay-150
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2]`}>Programme Details</p>
                    </a>
                    <a href='#faq'><p className={`ml-[3rem] py-1 transition ease-in-out delay-150  
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2]`}>FAQ</p>
                    </a>
                </>}

               {showRegister && <Link to='/register'><p className={`ml-[3rem] py-1 transition ease-in-out delay-150  
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2] ${registerBorderB} `}>Register</p>
                </Link>}

               {showScholarship && <Link to='/scholarship'><p className={`ml-[3rem] py-1 transition ease-in-out delay-150  
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2] ${registerBorderB} `}>Scholarships</p>
                </Link>}

               {showPayment && <Link to='/payment'><p className={`ml-[3rem] py-1 transition ease-in-out delay-150  
                    hover:-translate-y-1 hover:scale-110  duration-300  hover:text-[#ff30d2] ${registerBorderB} `}>Payment</p>
                </Link>}
            
            </section>
            
        </main>
    )
}

export default Navigations;