
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import golden from '../../../../packages/golden.json';
import platinum from '../../../../packages/platinum.json';
import general from '../../../../packages/general.json';


const Packages = () => {

    const [ref, inView] = useInView({

    });


    const [slicedItems, setSlicedItems] = useState(platinum.slice(0, 10))
    const [seeMore, setSeeMore] = useState(false)
    const [seeLess, setSeeLess] = useState(false)
    const [seeMoreRef, setSeeMoreRef] = useState(true)
    const [seeLessRef, setSeeLessRef] = useState(false)
   


    useEffect(() => {

        const setPlatinumArray = () => {

            if (seeMore) {
                setSlicedItems(platinum)

            } else if (seeLess) {
                setSlicedItems(platinum.slice(0, 10))

            }

            setSeeLess(false)
            setSeeMore(false)
        }

        setPlatinumArray()

    }, [slicedItems, seeMore, seeLess])


    return <>

        <main className='packages' ref={ref}>
            <section className='container pb-[5rem]'>
                <h1 className='text-[3.5rem] md:text-[5rem] herokid-bold text-white border-white border-b-4'>Packages</h1>
                <section className='pt-[2rem] min-[1120px]:flex min-[1120px]:flex-row min-[1120px]:place-content-center max-[1120px]:flex max-[1120px]:flex-col max-[1120px]:items-center'>
                    {<section className={`w-[24rem]  max-[422px]:w-[20rem] max-[422px]:h-[49rem] max-[600px]:h-[48rem] shadow-xlarge h-[46rem] 
                        rounded-xl  transition-transform duration-3000 transform ${inView ? 'translate-x-0' : '-translate-x-full'} max-[1115px]:mr-[0px] mr-4`}>
                        <div className=' text-center font-bold herokid-regular border-[#deec1d] border-b-2 relative'>
                            {/* <IoMdArrowRoundBack fontSize={30} color='#deec1d' className='fixed cursor-pointer' onClick={()=>{setGoldenDetail(false); setGoldDetailMinified(true)}} /> */}
                            <h1 className='text-[#deec1d] text-[2rem]'>General Access</h1>
                            <h2 className='text-white text-[1.5rem]'>GH₵900 / Person</h2>
                        </div>
                        <p className='text-white text-[1.5rem] px-2 herokid-regular font-bold'>What is included</p>
                        {general.map(((item) => <div className='px-2 py-2 flex items-center' key={item}>
                            <div>
                                <IoMdCheckmarkCircleOutline color='#deec1d' fontWeight='bolder' className='text-[1.5rem]' />
                            </div>
                            <p className='pl-2 small_pixel text-white  text-[#deedcc] text-[18px]'>{item}</p>
                        </div>))}
                        <div style={{ marginTop: '45px' }}></div>
                        {<div className='flex place-content-center py-2'>

                        </div>}
                    </section>}
                    {<section className={`w-[24rem]  max-[1115px]:mt-4 max-[422px]:w-[20rem] max-[422px]:h-[49rem] max-[600px]:h-[48rem] shadow-xlarge h-[46rem] 
                        rounded-xl  max-[1115px]:mr-[0px] mr-4 `}>
                        <div className=' text-center font-bold herokid-regular border-[#deec1d] border-b-2 relative'>
                            {/* <IoMdArrowRoundBack fontSize={30} color='#deec1d' className='fixed cursor-pointer' onClick={()=>{setGoldenDetail(false); setGoldDetailMinified(true)}} /> */}
                            <h1 className='text-[#deec1d] text-[2rem]'>Golden Access</h1>
                            <h2 className='text-white text-[1.5rem]'>GH₵1500 / Person</h2>
                        </div>
                        <p className='text-white text-[1.5rem] px-2 herokid-regular font-bold'>What is included</p>
                        {golden.map(((item) => <div className='px-2 py-2 flex items-center' key={item}>
                            <div>
                                <IoMdCheckmarkCircleOutline color='#deec1d' fontWeight='bolder' className='text-[1.5rem]' />
                            </div>
                            <p className='pl-2 small_pixel text-white  text-[#deedcc] text-[18px]'>{item}</p>
                        </div>))}
                        <div style={{ marginTop: '45px' }}></div>
                        {<div className='flex place-content-center py-2'>

                        </div>}
                    </section>}

                    {<section className={`w-[24rem] max-[422px]:w-[20rem]   max-[1115px]:mt-4 shadow-xlarge rounded-xl transition-transform duration-3000 transform ${inView ? '-translate-x-0' : 'translate-x-full'}`}>
                        <div className=' text-center herokid-regular font-bold border-[#deec1d] border-b-2 realtive'>
                            <h1 className='text-[#deec1d] text-[2rem]'>Platinum Access</h1>
                            <h2 className='text-white text-[1.5rem]'>GH₵2000 / Person</h2>
                        </div>
                        <p className='text-white text-[1.5rem] px-2 herokid-regular font-bold'>What is included</p>
                        {slicedItems.map(((item) => <div className='px-2 py-2 flex items-center' key={item}>
                            <div>
                                <IoMdCheckmarkCircleOutline color='#deec1d' fontWeight='bolder' className='text-[1.5rem]' />
                            </div>
                            <p className='pl-2 small_pixel text-white  text-[#deedcc] text-[18px]'>{item}</p>
                        </div>))}
                        {seeMoreRef && <p className='text-center font-bold cursor-pointer herokid-regular  text-[#deec1d] hover:text-white tracking-[0.1rem] text-[1.2rem]' onClick={() => { setSeeMore(true); setSeeMoreRef(false); setSeeLessRef(true) }}>See More</p>}
                        {seeLessRef && <p className='text-center font-bold cursor-pointer herokid-regular  text-[#deec1d] hover:text-white tracking-[0.1rem] text-[1.2rem]' onClick={() => { setSeeLess(true); setSeeMoreRef(true); setSeeLessRef(false) }}>See Less</p>}
                        {<div className='flex place-content-center py-2'>

                        </div>}
                    </section>}
                    {/* {platinumDetailMinified && <PlatinumMinified setPlatinumDetail={setPlatinumDetail} setPlatinumDetailMinified={setPlatinumDetailMinified} />} */}
                </section>
            </section>
        </main>
    </>
}

export default Packages;