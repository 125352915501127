import { useContext } from 'react';
import powerlogo from '../../images/POYF-03.png';
import Navigations from './Navigations';
import { GiHamburgerMenu } from 'react-icons/gi'
import {AiOutlineClose} from 'react-icons/ai'
import DataContext from '../../context/DataContext';

const Nav = () => {

    const {setShowHamburgerItems,showHamburgerButton, setShowHamburgerButton,
        showCloseButton, setShowCloseButton} = useContext(DataContext)

        const hambegerHandler = ()=>{
            setShowHamburgerItems(true)
            setShowCloseButton(true)
            setShowHamburgerButton(false)
        }

    return (
        <main className="py-2 bg-[#5030d2] fixed top-0 z-50 w-full " >
            <section className="container flex justify-between">
                <div className="flex flex-row " >
                    <img src={powerlogo} alt="logo.png" width={90} />
                    {/* <div className="ml-4 w-[35%]  font-extrabold text-[#deec1d] text-[1.2rem] md:text-[1.3rem] herokid-regular max-[1024px]:hidden"> 
                        <p>POWER</p>
                        <p>OF YOU</p>
                        <p>FESTIVAL</p>
                    </div> */}
                </div>
                <Navigations />
                <div className='flex items-center cursor-pointer min-[1024px]:hidden '>
                    {showHamburgerButton && <GiHamburgerMenu fontSize={24} color='white' className='max-[500px]:text-[1.4rem]' onClick={hambegerHandler} />}
                    {showCloseButton && <AiOutlineClose fontSize={24} color='white' className='max-[500px]:text-[1.2rem]'/>}
                </div>
            </section>
        </main>
    )
}

export default Nav;