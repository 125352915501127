
import { useState, useContext, useEffect } from "react";

import DataContext from "../../../../../context/DataContext";



const InGroupInfo = () => {

    const [nChecked, setNchecked] = useState(false)
    const [yChecked, setYchecked] = useState(false)
    const [specialYChecked, setSpecialYChecked] = useState(false)
    const [specialNchecked, setSpecialNchecked] = useState(false)
    const [medYChecked, setMedYChecked] = useState(false)
    const [medNchecked, setMedNchecked] = useState(false)
    const [selectedDays, setSelectedDays] = useState([])
    const [selectMeals, setSelectedMeals] = useState([])
    const [personalInfoFieldError, setPersonalInfoFieldError] = useState(false)


    const { dispatch, personalInfoStateDispatch, personalInfoState } = useContext(DataContext)

    // console.log(personalInfoState.in_egroup)


    const days = ['Thursday', 'Friday', 'Saturday', 'Sunday']
    const meals = ['Regular', 'Gluten Free', 'Vegan', 'Vegetarian', 'Dairy Free']

    const perInfoStates = [

        personalInfoState.first_name,
        personalInfoState.last_name,
        personalInfoState.phone_number,
        personalInfoState.email,
        personalInfoState.age,
        personalInfoState.gender,
        personalInfoState.campus,
        personalInfoState.shirt_size,
        personalInfoState.address,
        personalInfoState.city,
        personalInfoState.region,
        personalInfoState.zip_code,
        personalInfoState.in_egroup,
        personalInfoState.meal_plan,
        personalInfoState.ward_has_special_needs,
        personalInfoState.wards_minor_injury_medication,

    ]



    const continuePageHandler = () => {

        const isEmpty = perInfoStates.some((item) => item === '') 
    
            if (isEmpty) {
                setPersonalInfoFieldError(true)
                setTimeout(() => setPersonalInfoFieldError(false), 1500)
            } else {
                dispatch({ type: 'insurance', payload: true })
                dispatch({ type: 'personalinfo', payload: false })
            }

        personalInfoStateDispatch({ type: 'days_of_week_grouping', payload: (selectedDays.join(',')) })
        personalInfoStateDispatch({ type: 'meal_plan', payload: (selectMeals.join(',')) })
    }



    const getDay = (dayName) => {

        if (selectedDays.includes(dayName)) {
            setSelectedDays(selectedDays.filter((selected) => selected !== dayName));

        } else {
            setSelectedDays([...selectedDays, dayName]);
        }
    }

    const getMeal = (mealName) => {

        if (selectMeals.includes(mealName)) {
            setSelectedMeals(selectMeals.filter((selected) => selected !== mealName))
        } else {
            setSelectedMeals([...selectMeals, mealName])
        }
    }

    useEffect(()=>{

        if(selectMeals){
            personalInfoStateDispatch({ type: 'meal_plan', payload: (selectMeals.join(',')) })
        }

        if(selectedDays){
            personalInfoStateDispatch({ type: 'days_of_week_grouping', payload: (selectedDays.join(',')) })
        }

    }, [selectMeals, selectedDays, personalInfoStateDispatch])


    return <>

        <main className="mt-2 bg-white w-[100%] rounded-xl shadow-medium">
            <section className="px-5 py-5 ">
                <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
                    <div className="  w-[48%]  max-[624px]:w-[100%]">
                        <p className="text-[18.5px]">Are you in an eGroup<sup className="text-[1rem] text-red-500">*</sup></p>
                    </div>
                    <section className=" w-[48%] ">
                        <section className="flex flex-row text-[18.5px]">
                            <div className="flex place-content-center items-center">
                                <input type="radio" id="group_yes"
                                    value='Yes'
                                    checked={yChecked}
                                    onChange={(e) => { setYchecked(true); setNchecked(false); personalInfoStateDispatch({ type: 'in_egroup', payload: e.target.value }) }} />
                                <label htmlFor="group_yes" className="ml-2">Yes</label>
                            </div>
                            <div className="ml-[10%] flex place-content-center items-center">
                                <input type="radio" id="group_no" value='No' checked={nChecked}
                                    onChange={(e) => { setYchecked(false); setNchecked(true); personalInfoStateDispatch({ type: 'in_egroup', payload: e.target.value }) }} />
                                <label htmlFor="group_no" className="ml-2">No</label>
                            </div>
                        </section>
                    </section>
                </section>
                {nChecked && <section className="flex flex-col small_pixel py-2 ">
                    <div className="  w-[100%]">
                        <p className="text-[18.5px]">Which day(s) of the week would you be able to be in an eGroup</p>
                    </div>
                    <section className="flex flex-wrap justify-between w-[80%] text-[18.5px]">
                        {days.map((day) => <div key={day} className="flex place-content-center items-center">
                            <input type="checkbox" value={day} id={day} checked={selectedDays.includes(day)} onChange={() => { getDay(day) }} />
                            <p className="pl-2">{day}</p>
                        </div>)}
                    </section>
                </section>}
                {yChecked && <section className="small_pixel py-2">
                    <div className="flex flex-col  w-[100%] py-2">
                        <label htmlFor="group-leader" className="text-[18.5px]">Who is your eGroup Leader? (Full Name)</label>
                        <input type="text" id='group-leader' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.egroup_leader} onChange={(e) => personalInfoStateDispatch({ type: 'egroup_leader', payload: e.target.value })}
                        />
                    </div>
                </section>}
                <section className="small_pixel py-2">
                    <div className="flex flex-col  w-[100%] py-2">
                        <label htmlFor="group-friends" className="text-[18.5px]">If your ward has friends he or she would like to be grouped with, please list full names separated by commas here.
                            Due to ratio and capacity limitations, we cannot guarantee that all requests will be met.
                        </label>
                        <input type="text" id='group-friends' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.friends_to_group_with} onChange={(e) => personalInfoStateDispatch({ type: 'friends_to_group_with', payload: e.target.value })}

                        />
                    </div>
                </section>
                <section className="small_pixel py-2">
                    <div className="flex flex-col  w-[100%] py-2">
                        <label htmlFor="medication" className="text-[18.5px]">Medication</label>
                        <input type="text" id='medication' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.medication} onChange={(e) => personalInfoStateDispatch({ type: 'medication', payload: e.target.value })}
                        />
                    </div>
                </section>
                <section className="small_pixel py-2">
                    <div className="flex flex-col  w-[100%] py-2">
                        <label htmlFor="Allergies" className="text-[18.5px]">Allergies</label>
                        <input type="text" id='Allergies' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.allergies} onChange={(e) => personalInfoStateDispatch({ type: 'allergies', payload: e.target.value })}
                        />
                    </div>
                </section>
                <section className="flex flex-col small_pixel py-2 ">
                    <div className="w-[100%]">
                        <p className="text-[18.5px]">Meal Plan (Please select all that apply)<sup className="text-[1rem]">*</sup></p>
                    </div>
                    <section className="flex flex-wrap flex-col w-[80%] text-[18.5px] pt-[0.1rem]">
                        {meals.map((meal) => <div key={meal} className="flex items-center">
                            <input type="checkbox" id={meal} checked={selectMeals.includes(meal)} onChange={() => getMeal(meal)} />
                            <p className="pl-2">{meal}</p>
                        </div>)}
                    </section>
                </section>
                <section className="flex flex-col small_pixel py-2">
                    <div className="  w-[100%]">
                        <p className="text-[18.5px]">Please let us know if your ward has any special needs or
                            considerations so we are able to best accommodate them<sup className="text-[1rem]">*</sup>
                        </p>
                    </div>
                    <section className=" w-[48%] ">
                        <section className="flex flex-row text-[18.5px]">
                            <div className="flex place-content-center items-center">
                                <input type="radio" id="special_yes" value='Yes' checked={specialYChecked} onChange={(e) => { setSpecialYChecked(true); setSpecialNchecked(false); personalInfoStateDispatch({ type: 'ward_has_special_needs', payload: e.target.value }) }} />
                                <label htmlFor="group_yes" className="ml-2">Yes</label>
                            </div>
                            <div className="ml-[10%] flex place-content-center items-center">
                                <input type="radio" id="special_no" value='No' checked={specialNchecked} onChange={(e) => { setSpecialYChecked(false); setSpecialNchecked(true); personalInfoStateDispatch({ type: 'ward_has_special_needs', payload: e.target.value }) }} />
                                <label htmlFor="group_no" className="ml-2">No</label>
                            </div>
                        </section>
                    </section>
                </section>
                {specialYChecked && <section className="small_pixel py-2">
                    <div className="flex flex-col  w-[100%]">
                        <label htmlFor="special_need" className="text-[18.5px]">Special Needs</label>
                        <input type="text" id='special_need' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.special_needs} onChange={(e) => personalInfoStateDispatch({ type: 'special_needs', payload: e.target.value })}
                        />
                    </div>
                </section>}
                <section className="flex flex-col small_pixel py-2">
                    <div className="  w-[100%]">
                        <p className="text-[18.5px]">If your ward has a fever, headache, or minor injury, do you consent
                            to providing them medication without calling you first?<sup className="text-[1rem]">*</sup>
                        </p>
                    </div>
                    <section className=" w-[48%] ">
                        <section className="flex flex-row text-[18.5px]">
                            <div className="flex place-content-center items-center">
                                <input type="radio" id="medication_yes" value='Yes' checked={medYChecked} onChange={(e) => { setMedYChecked(true); setMedNchecked(false); personalInfoStateDispatch({ type: 'wards_minor_injury_medication', payload: e.target.value }) }} />
                                <label htmlFor="group_yes" className="ml-2">Yes</label>
                            </div>
                            <div className="ml-[10%] flex place-content-center items-center">
                                <input type="radio" id="medication_no" value='No' checked={medNchecked} onChange={(e) => { setMedYChecked(false); setMedNchecked(true); personalInfoStateDispatch({ type: 'wards_minor_injury_medication', payload: e.target.value }) }} />
                                <label htmlFor="group_no" className="ml-2">No</label>
                            </div>
                        </section>
                    </section>
                </section>
                {medYChecked && <section className="small_pixel py-2">
                    <div className="flex flex-col w-[100%]">
                        <label htmlFor="provide_medication" className="text-[18.5px]">Provide medication(s) you consent for our team to provide:</label>
                        <input type="text" id='provide_medication' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                            value={personalInfoState.medications_consent} onChange={(e) => personalInfoStateDispatch({ type: 'medications_consent', payload: e.target.value })}
                        />
                    </div>
                </section>}
                {personalInfoFieldError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px]">all recommended fields (*) must be filled</p>}
                <div className="flex justify-end">
                    <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18.5px] cursor-pointer hover:bg-[blue]" onClick={continuePageHandler}>
                        continue
                    </button>
                </div>
            </section>
        </main>

    </>
}

export default InGroupInfo;