
import { useContext } from "react";
import InGroupInfo from "./personalInfoItems/InGroupInfo";
import ScrollToTop from "../ScrollToTop";
import DataContext from "../../../../context/DataContext";


const PersonalInfo = () => {

  const { personalInfoState, personalInfoStateDispatch } = useContext(DataContext)

  return <>

    <ScrollToTop />
    <main className="mt-1  w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%]  ">
      <section className="mt-1 bg-white w-[100%] rounded-xl shadow-medium ">
        <section className="px-5 py-5 ">
          <section className="small_pixel min-[624px]:flex min-[624px]:justify-between">
            <div className="flex flex-col w-[48%] max-[624px]:w-[100%] ">
              <label htmlFor="firstName" className="text-[18.5px]">First Name<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='firstName' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.first_name} onChange={(e) => personalInfoStateDispatch({ type: 'first_name', payload: e.target.value })}
              />
            </div>
            <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%] max-[624px]:pt-3 ">
              <label htmlFor="lastName" className="text-[18.5px]">Last Name<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='lastName' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.last_name} onChange={(e) => personalInfoStateDispatch({ type: 'last_name', payload: e.target.value })}
              />
            </div>
          </section>
          <section className=" min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
            <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%]">
              <label htmlFor="personal_gender" className="text-[18.5px]">Gender<sup className="text-[1rem]">*</sup></label>
              <select id='personal_gender' className="border border-[grey] rounded-lg py-[0.5rem] px-2"
                value={personalInfoState.gender} onChange={(e) => personalInfoStateDispatch({ type: 'gender', payload: e.target.value })}
              >
                <option value="">choose an option</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%]  max-[624px]:pt-3">
              <label htmlFor="dob" className="text-[18.5px]">Age<sup className="text-[1rem]">*</sup></label>
              <input type="number" min={10} max={30} id='dob' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.age} onChange={(e) => personalInfoStateDispatch({ type: 'age', payload: e.target.value })}
              />
            </div>
          </section>
          <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
              <label htmlFor="phoneNumber" className="text-[18.5px]">Phone Number<sup className="text-[1rem]">*</sup></label>
              <input type="tel" id='phoneNumber' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.phone_number} onChange={(e) => personalInfoStateDispatch({ type: 'phone_number', payload: e.target.value })}
              />
            </div>
            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
              <label htmlFor="email" className="text-[18.5px]">Email<sup className="text-[1rem]">*</sup></label>
              <input type="email" id='email' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                value={personalInfoState.email} onChange={(e) => personalInfoStateDispatch({ type: 'email', payload: e.target.value })}
              />
            </div>
          </section>
          <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
              <label htmlFor="campus" className="text-[18.5px]">Campus<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='campus' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.campus} onChange={(e) => personalInfoStateDispatch({ type: 'campus', payload: e.target.value })}
              />
            </div>
            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
              <label htmlFor="shirt" className="text-[18.5px]">T-shirt Size(Adult sizes)<sup className="text-[1rem]">*</sup></label>
              <select type="text" id='shirt' className="border border-[grey] rounded-lg py-[0.5rem] px-2"
                value={personalInfoState.shirt_size} onChange={(e) => personalInfoStateDispatch({ type: 'shirt_size', payload: e.target.value })}
              >
                <option value="">choose an option</option>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
                <option value="2XL">2XL</option>
              </select>
            </div>
          </section>
          <section className="small_pixel py-2">
            <div className="flex flex-col  w-[100%] py-2">
              <label htmlFor="address" className="text-[18.5px]">Address<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='address' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                value={personalInfoState.address} onChange={(e) => personalInfoStateDispatch({ type: 'address', payload: e.target.value })}
              />
            </div>
          </section>
          <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
            <div className="flex flex-col  w-[30%] max-[624px]:w-[100%]">
              <label htmlFor="city" className="text-[18.5px]">City<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='city' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.city} onChange={(e) => personalInfoStateDispatch({ type: 'city', payload: e.target.value })}
              />
            </div>
            <div className="flex flex-col  w-[30%] max-[624px]:w-[100%] max-[624px]:pt-3">
              <label htmlFor="state" className="text-[18.5px]">Region<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='state' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.region} onChange={(e) => personalInfoStateDispatch({ type: 'region', payload: e.target.value })}
              />
            </div>
            <div className="flex flex-col  w-[30%] max-[624px]:w-[100%] max-[624px]:pt-3">
              <label htmlFor="zip" className="text-[18.5px]">Zip Code<sup className="text-[1rem]">*</sup></label>
              <input type="number" min={0} id='zip' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.zip_code} onChange={(e)=>personalInfoStateDispatch({type:'zip_code', payload:e.target.value})}
              />
            </div>
          </section>
        </section>
      </section>
      <InGroupInfo />
    </main>

  </>
}

export default PersonalInfo;