
import { useContext, useRef } from "react";
import DataContext from "../../../../context/DataContext";


const PaymentPlanModal = ({ setPartPaymentModal, setNchecked }) => {

    const { payDispatch} = useContext(DataContext)

    const partPaymentValue = useRef()

    const partPaymentHandler = ()=>{
        payDispatch({ type: 'amount', payload: partPaymentValue.current.value })
        setPartPaymentModal(false)
        setNchecked(false)
    }

   

    return <>

        <div className="backdrop" onClick={() => { setPartPaymentModal(false); setNchecked(false) }}></div>
        <main className=" w-[32rem] max-[568px]:w-[100%] rounded-xl bg-white px-[1rem] modal-modal border border-3 border-[grey] fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
            <h2 className="text-center herokid-regular font-bold text-[1.05rem]  pt-3">Enter the amount of partial payment you wish to make</h2>
            <div className="pb-[2rem]">
                <section className="small_pixel text-[18.5px] pt-[0.5rem] px-[4rem]">
                    <div className="flex flex-col  w-[100%] py-2">
                        <label htmlFor="amount_partpayment" className="">Amount(GH₵)<sup className="text-[1rem]">*</sup></label>
                        <input type="number" id='amount_partpayment' className="border-b-2 border-b-[grey]
                                 bg-[#f1f1f1] outline-none py-[0.15rem] px-2"
                              ref = {partPaymentValue}   
                        />
                    </div>
                </section>
            </div>
            <div className="flex justify-center">

                <button className="py-2 w-[40%]  bg-[#5030d2] rounded-xl text-white p-4 small_pixel cursor-pointer hover:bg-[blue]"
                    onClick={partPaymentHandler}
                >
                    Continue
                </button>
            </div>
        </main>
    </>
}

export default PaymentPlanModal;