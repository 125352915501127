
import { DataProvider } from "./context/DataContext";
import MainBody from "./components/mainbody/MainBody";
import { Routes, Route } from "react-router-dom";
import MissingPage from "./components/mainbody/mainbodyItems/MissingPage";
import Scholarships from "./components/mainbody/mainbodyItems/RegisterRouteItems/Scholarships";
import ScholarshipForm from "./components/mainbody/mainbodyItems/RegisterRouteItems/scholarshipItems/ScholarshipForms";
import Home from "./components/mainbody/mainbodyItems/Home";
import Register from "./components/mainbody/mainbodyItems/Register";
import Payment from "./components/mainbody/mainbodyItems/Payment";
import GiftPayment from "./components/mainbody/mainbodyItems/RegisterRouteItems/GiftPayment";


const App = () => {

  return (
    <>
      <DataProvider>
        <Routes>
          <Route  path="/" element={<MainBody />} >
          <Route index path='/' element={<Home />} />
            <Route path='/scholarship' element={<Scholarships />} />
            <Route path='/register' element={<Register />} />
            <Route path='/payment' element={ <Payment />} />
            <Route path='/gift' element={<GiftPayment />} />
            <Route path='/scholarshipform' element={<ScholarshipForm/>}/>
          </Route>
          <Route path='*' element={<MissingPage />} />
        </Routes>
      </DataProvider>
    </>
  );
}

export default App;


