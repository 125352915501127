
import { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import discussion from '../../../../images/carousel/img7.jpg'
import entertainment from '../../../../images/entertain.jpg'
import network from '../../../../images/carousel/img5.jpg'
import img4 from '../../../../images/carousel/img4.jpg'
import DataContext from '../../../../context/DataContext';



const CampExperience = () => {

    const { setRegisterButtonModal } = useContext(DataContext)

    const [ref, inView] = useInView({

    });

    return (<>
        <main className="camp h-[90rem] max-[1025px]:h-[165rem] max-[572px]:h-[142rem]"  >
            <section className={`container`} >
                <div className='relative border-[#deec1d]  border-b-4'>
                    <h1 className="herokid-bold font-bold text-[8rem]  max-[1025px]:text-[6rem] max-[401px]:text-[3rem] text-white">Camp</h1>
                    <h1 className="herokid-bold font-bold text-[1.8rem] text-white mt-[-3.5rem] absolute left-[7rem]  max-[1025px]:mt-[-2.8rem]  max-[1025px]:absolute  
                    max-[1025px]:left-[2.2rem] max-[401px]:text-[0.9rem]  max-[401px]:mt-[-1.3rem] max-[401px]:left-[0.5rem]   ">EXPERIENCE</h1>
                </div>
                <div className={`py-[2rem] pt-[3rem] mt-[-2rem] flex justify-between max-[1025px]:flex max-[1025px]:flex-col  transition-transform duration-1000 transform ${inView ? 'translate-x-0' : '-translate-x-full'} `} ref={ref} >
                    <section className='flex flex-col items-center shadow-xlarge p-2 rounded-xl '>
                        <div className=''>
                            <h2 className='herokid-bold font-bold relative bottom-[-2rem]  text-[3rem] max-[1025px]:text-[2.5rem] max-[1025px]:bottom-[-1.8rem] text-white text-center'>Education</h2>
                            <img src={discussion} alt="discussion.webp" className='h-[30rem] w-[24rem] max-[1285px]:h-[20rem] 
                            max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem]  object-cover rounded-xl' />
                        </div>
                        <div className='w-[24rem] max-[1285px]:w-[20rem]  max-[1025px]:w-[35rem] max-[572px]:w-[20rem]'>
                            <h4 className='font-semibold text-center herokid-regular font-bold text-[25px] text-[#deedcc]'>Knowledge Enhancement</h4>
                            <p className='text-[20px] small_pixel text-center text-white'>Campers will engage in enriching learning experiences, attend engaging workshops,
                                and acquire valuable knowledge and skills to empower themselves</p>
                        </div>
                    </section>
                    <section className='flex flex-col items-center shadow-xlarge p-2 rounded-xl '>
                        <div className=''>
                            <h2 className='herokid-bold font-bold relative bottom-[-2rem]  text-[3rem] max-[1025px]:text-[2.5rem] max-[1025px]:bottom-[-1.8rem] text-white text-center'>Entertainment</h2>
                            <img src={entertainment} alt="entertainment.webp" className='h-[30rem] w-[24rem]
                             max-[1285px]:h-[20rem] max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem]  object-cover rounded-xl' />
                        </div>
                        <div className='w-[24rem]  max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[572px]:w-[20rem] '>
                            <h4 className='herokid-regular font-bold text-center text-[25px] text-[#deedcc]'>Camp Enjoyment</h4>
                            <p className='text-[20px] small_pixel text-white text-center'>Campers will delight in a wide range of recreational activities, unwind with nightly entertainment, embark on exciting outdoor adventures, and partake in cultural experiences</p>
                        </div>
                    </section>
                    <section className='flex flex-col items-center shadow-xlarge p-2 rounded-xl'>
                        <div className=''>
                            <h2 className='herokid-bold font-bold relative bottom-[-2rem]  text-[3rem] max-[1025px]:text-[2.5rem] max-[1025px]:bottom-[-1.8rem] text-white text-center '>Networking</h2>
                            <img src={network} alt="network.png" className='h-[30rem] w-[24rem] max-[1285px]:h-[20rem] max-[1285px]:w-[20rem]  max-[1025px]:w-[35rem] max-[1025px]:h-[30rem] max-[572px]:w-[20rem] max-[572px]:h-[20rem] object-cover rounded-xl' />
                        </div>
                        <div className='w-[24rem] max-[1285px]:w-[20rem] max-[1025px]:w-[35rem] max-[572px]:w-[20rem]'>
                            <h4 className='herokid-regular font-bold text-center text-[25px] text-[#deedcc]'>Connect and Flourish</h4>
                            <p className='text-[20px] small_pixel text-white text-center'>Campers will have  opportunities  to connect with peers, seek mentorship, socialise during camp-wide events, and maintain connections through our online community</p>
                        </div>
                    </section>
                </div>
            </section>
            <div className='flex place-content-center  pt-[2rem] pb-[6rem] max-[572px]:pb-[2rem]'>
                <button className='bg-[#deec1d] small_pixel font-semibold rounded-[5rem] 
                text-[18px] p-3 px-[3rem] cursor-pointer hover:bg-[black] hover:text-[#deec1d]' onClick={() => setRegisterButtonModal(true)}>REGISTER NOW</button>
            </div>
            <section className='flex place-content-center relative top-[-4rem] max-[720px]:top-[-4rem] max-[572px]:top-[1rem]  z-10' >
                <div className='w-9/12 '>
                    <img src={img4} loading='lazy' alt="" className='h-[35rem] max-[724px]:h-[25rem] t object-cover darker-image rounded-[1rem] w-[100%]' />
                    <div className='flex place-content-center'>
                        <h2 className="text-[3.2rem] tracking-[0.1rem] w-[60%]  
                            text-center font-bold herokid-regular max-[1153px]:text-[2.8rem] max-[1040px]:text-[2.4rem] 
                            max-[400px]:text-[1.2rem] absolute top-[4rem]  max-[1040px]:top-[2rem] text-[#deec1d] max-[724px]:text-[1.8rem]  max-[572px]:text-[1.4rem]"
                        >

                            <p className="py-5">The actual experience exceeds all expectations and is difficult to capture in words.</p> It will
                            unleash the power within Younger generation.
                        </h2>
                    </div>
                </div>
            </section>
        </main>
    </>)

}

export default CampExperience;

