
import { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import DataContext from "../../../../../context/DataContext";
import ScholarshipSubmit from "./ScholarshipSubmit";
import ScholarshipRegisterSuccess from "./ScholarshipRegisterSuccess";


const ScholarshipForm = () => {

    const [nChecked, setNchecked] = useState(false)
    const [yChecked, setYchecked] = useState(false)
    const [selectedConditions, setSelectedConditions] = useState([])
    const [scholarshipError, setScholarshipError] = useState(false)
    const [applicationError, setApplicationError] = useState(false)
    const [applicationErrorMessage, setApplicationErrorMessage] = useState('')
    const [showScholarshipRegSuccess, setShowScholarshipRegSuccess] = useState(false)

    const conditions = [
        "I am either between the ages of 11-22",
        "I don't have the financial capability to pay for a package",
        "I acknowledge that I will bear the costs of any extra expenses (such as travel, accommodation, and meals) associated with attending Power of You Festival 2024.",
    ]

    const { setShowContactUs, scholarshipState, scholarshipDispatch } = useContext(DataContext)


    useEffect(() => {
        if (selectedConditions) {
            scholarshipDispatch({ type: 'qualification', payload: (selectedConditions.join(',')) })
        }
    }, [selectedConditions, scholarshipDispatch])



    const getSelectedConditions = (conditionName) => {

        if (selectedConditions.includes(conditionName)) {
            setSelectedConditions(selectedConditions.filter((selected) => selected !== conditionName));

        } else {
            setSelectedConditions([...selectedConditions, conditionName]);

        }
    }



    return <>
        <Helmet>
            <title>Power of You 2024 - Scholarship Forms</title>
            <meta name='description' content='Apply for scholarship-power of You 2024 - scholarship forms' />
            <link rel="canonical" href="https://powerofyoufestival.com/scholarshipform" />
        </Helmet>
        {showScholarshipRegSuccess && <ScholarshipRegisterSuccess />}
        <main className="mt-[6rem] flex-grow">
            <section className="background-element h-[28.5rem] ">
                <div className="container  h-[100%]  flex items-center place-content-center ">
                    <div className="text-white text-center">
                        <h1 className="herokid-bold  text-center text-white  text-[4rem] max-[772px]:text-[3rem] leading-[3.5rem]">SCHOLARSHIP APPLICATION</h1>
                        <p className="small_pixel text-[2rem] max-[772px]:text-[1rem]">POWER OF YOU FESTIVAL 2024</p>
                        <div className="container flex flex-col place-content-center items-center ">
                            <p className="text-center pt-[1.5rem] pb-[1rem]  w-[50%] max-[772px]:w-[100%] small_pixel  text-[1.3rem] max-[526px]:text-[0.9rem] ">
                                A limited number of scholarships are available for individuals aged 11-22  to attend Power of You Festival 2024 from October 9th-October 13th October, 2024!
                            </p>
                            <p className="text-center  w-[50%] max-[772px]:w-[100%] small_pixel  text-[1.3rem] max-[526px]:text-[0.9rem] ">
                                For inquiries regarding Power of You scholarships, please reach out to us
                                <span className="tracking-[0.15rem] herokid-bold  cursor-pointer text-white" onClick={() => setShowContactUs(true)} > HERE</span>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="flex place-content-center py-[3rem]">

                <section className="mt-1  w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%]  ">
                    <section className="mt-1 bg-white w-[100%] rounded-xl shadow-medium ">
                        <p className="herokid-regular font-bold text-center py-5">NOTE: Before Proceeding with the scholarship application, please make sure you have first registered to the event </p>
                        <section className="px-5 py-5 ">
                            <section className="pb-5">
                                <p className="text-[18.5px] pb-[0.4rem] small_pixel">
                                    To qualify as a recipient of a Power of You Festival 2024 Scholarship, you are required to adhere to the following conditions:<sup className="text-[1rem]">*</sup>
                                </p>
                                <section className="flex flex-col small_pixel text-[18.5px] ">
                                    {conditions.map((condition) => <div key={condition} >
                                        <input type="checkbox" id={condition} value={condition}
                                            checked={selectedConditions.includes(condition)}
                                            onChange={() => getSelectedConditions(condition)} />

                                        <label htmlFor={condition} className="ml-2">{condition}</label>
                                    </div>)}
                                </section>
                            </section>
                            <section className="small_pixel min-[624px]:flex min-[624px]:justify-between">
                                <div className="flex flex-col w-[48%] max-[624px]:w-[100%] ">
                                    <label htmlFor="schol_firstName" className="text-[18.5px]">First Name<sup className="text-[1rem]">*</sup></label>
                                    <input type="text" id='schol_firstName' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.first_name} onChange={(e) => scholarshipDispatch({ type: 'first_name', payload: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%] max-[624px]:pt-3 ">
                                    <label htmlFor="schol_lastName" className="text-[18.5px]">Last Name<sup className="text-[1rem]">*</sup></label>
                                    <input type="text" id='schol_lastName' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.last_name} onChange={(e) => scholarshipDispatch({ type: 'last_name', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            <section className=" min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
                                <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%]">
                                    <label htmlFor="scholarshipGender" className="text-[18.5px]">Gender<sup className="text-[1rem]">*</sup></label>
                                    <select id='scholarshipGender' className="border border-[grey] rounded-lg py-[0.56rem] px-2"
                                        value={scholarshipState.gender} onChange={(e) => scholarshipDispatch({ type: 'gender', payload: e.target.value })}
                                    >
                                        <option value="">choose an option</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%]  max-[624px]:pt-3">
                                    <label htmlFor="schol_age" className="text-[18.5px]">Age<sup className="text-[1rem]">*</sup></label>
                                    <input type="number" min={10} max={30} id='schol_age' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.age} onChange={(e) => scholarshipDispatch({ type: 'age', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
                                <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
                                    <label htmlFor="schol_phoneNumber" className="text-[18.5px]">Phone Number<sup className="text-[1rem]">*</sup></label>
                                    <input type="tel" id='schol_phoneNumber' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.phone_number} onChange={(e) => scholarshipDispatch({ type: 'phone_number', payload: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
                                    <label htmlFor="schol_email" className="text-[18.5px]">Email<sup className="text-[1rem]">*</sup></label>
                                    <input type="email" id='schol_email' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                                        value={scholarshipState.email} onChange={(e) => scholarshipDispatch({ type: 'email', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            <section className="small_pixel py-2">
                                <div className="flex flex-col  w-[100%] py-2">
                                    <label htmlFor="schol_address" className="text-[18.5px]">Address<sup className="text-[1rem]">*</sup></label>
                                    <input type="text" id='schol_address' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                                        value={scholarshipState.address} onChange={(e) => scholarshipDispatch({ type: 'address', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            <section className="min-[624px]:flex min-[624px]:justify-between small_pixel py-2">
                                <div className="flex flex-col  w-[30%] max-[624px]:w-[100%]">
                                    <label htmlFor="schol_city" className="text-[18.5px]">City<sup className="text-[1rem]">*</sup></label>
                                    <input type="text" id='schol_city' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.city} onChange={(e) => scholarshipDispatch({ type: 'city', payload: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col  w-[30%] max-[624px]:w-[100%] max-[624px]:pt-3">
                                    <label htmlFor="schol_state" className="text-[18.5px]">Region<sup className="text-[1rem]">*</sup></label>
                                    <input type="text" id='schol_state' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.region} onChange={(e) => scholarshipDispatch({ type: 'region', payload: e.target.value })}
                                    />
                                </div>
                                <div className="flex flex-col  w-[30%] max-[624px]:w-[100%] max-[624px]:pt-3">
                                    <label htmlFor="schol_zip" className="text-[18.5px]">Zip Code<sup className="text-[1rem]">*</sup></label>
                                    <input type="number" id='schol_zip' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                                        value={scholarshipState.zip_code} onChange={(e) => scholarshipDispatch({ type: 'zip_code', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            <section className="min-[624px]:flex min-[624px]:justify-between small_pixel pt-[2rem]">
                                <div className="  w-[48%]  max-[624px]:w-[100%]">
                                    <p className="text-[18.5px]">Have you attended a Power of You Festival before?<sup className="text-[1rem]">*</sup></p>
                                </div>
                                <section className=" w-[48%] ">
                                    <section className="flex flex-row text-[18.5px]">
                                        <div className="flex place-content-center items-center">
                                            <input type="radio" id="schol_attended_yes" value='Yes' checked={yChecked} onChange={(e) => { setYchecked(true); setNchecked(false); scholarshipDispatch({ type: 'attended_before', payload: e.target.value }) }} />
                                            <label htmlFor="schol_attended_yes" className="ml-2">Yes</label>
                                        </div>
                                        <div className="ml-[10%] flex place-content-center items-center">
                                            <input type="radio" id="schol_attended_no" value='No' checked={nChecked} onChange={(e) => { setYchecked(false); setNchecked(true); scholarshipDispatch({ type: 'attended_before', payload: e.target.value }) }} />
                                            <label htmlFor="schol_attended_no" className="ml-2">No</label>
                                        </div>
                                    </section>
                                </section>
                            </section>
                            <section className="small_pixel pt-[1rem]">
                                <div className="flex flex-col  w-[100%] py-2">
                                    <label htmlFor="schol_message" className="text-[18.5px] pb-[0.4rem]">Kindly share a short explanation why
                                        you are applying for Power of You Festival 2024 scholarship<sup className="text-[1rem]">*</sup></label>
                                    <textarea id='schol_message' className="border border-[grey] border-b-2 border-b-[grey] text-[18.5px] outline-none  px-2 rounded-lg h-[10rem]"
                                        value={scholarshipState.reason_for_application} onChange={(e) => scholarshipDispatch({ type: 'reason_for_application', payload: e.target.value })}
                                    />
                                </div>
                            </section>
                            {scholarshipError && <p className=" text-center text-[red] small_pixel font-bold">all recommended fields (*) must be filled</p>}
                            {applicationError && <p className=" text-center text-[red] small_pixel font-bold">{applicationErrorMessage}</p>}
                            <div className="flex justify-center pt-[1.5rem]">
                                <ScholarshipSubmit
                                    setScholarshipError={setScholarshipError}
                                    setShowScholarshipRegSuccess={setShowScholarshipRegSuccess}
                                    setSelectedConditions={setSelectedConditions}
                                    setNchecked={setNchecked}
                                    setYchecked={setYchecked}
                                    setApplicationError={setApplicationError}
                                    setApplicationErrorMessage={setApplicationErrorMessage}
                                />
                            </div>
                        </section>
                    </section>
                </section>
            </section>
        </main>
    </>
}

export default ScholarshipForm;