
import { useContext, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FaHome, FaQuestionCircle } from 'react-icons/fa'
import DataContext from '../../context/DataContext'
import { AiOutlineClose } from 'react-icons/ai'
import { RiMiniProgramLine } from 'react-icons/ri'
import { GiArchiveRegister } from 'react-icons/gi'
import { RiTwitterXFill } from 'react-icons/ri'
import { TfiInstagram } from 'react-icons/tfi'
import { LiaLinkedinIn } from 'react-icons/lia'
import { TbPackages } from 'react-icons/tb'


const HamburgerItems = () => {

    const { setShowHamburgerItems, setShowCloseButton, setShowHamburgerButton, setRegisterButtonModal } = useContext(DataContext)

    const [showHome, setShowHome] = useState(true)


    const location = useLocation()

    useEffect(() => {
        if (location.pathname === '/') {
            setShowHome(true)

        }


        if (location.pathname === '/register' || location.pathname === '/payment' || location.pathname === '/scholarship' || location.pathname === '/scholarshipform' || location.pathname === '/gift') {
            setShowHome(false)

        }

    }, [location.pathname])



    return <>

        <div className='backdrop min-[1024px]:hidden' onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }}></div>

        <main className={`text-white text-sm fixed top-[0] flex flex-col  p-side h-screen  w-9/12 max-w-[20rem] p-1  rounded z-50 min-[1024px]:hidden animate-slideIn`}>
            <div className='flex justify-end cursor-pointer mt-4 pr-2  '>
                <AiOutlineClose fontSize={24} className='max-[500px]:text-[1.2rem]' onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }} />
            </div>
            <div className='mt-[11rem] pl-[10%]'>
                <section className='shadow-medium text-white py-4 pl-2 flex flex-row cursor-pointer transition ease-in-out delay-150 
                            hover:-translate-y-1 hover:scale-110  duration-300'>
                    <div className='flex  place-content-center items-center'>
                        <FaHome fontSize={18} className='' />
                    </div>
                    <Link to='/'>
                        <p className={`ml-2 font-semibold`} onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }}>
                            ABOUT</p>
                    </Link>
                </section>

                {showHome && <>
                    <section className='text-white mt-5 flex flex-row cursor-pointer shadow-medium py-4 pl-2 transition ease-in-out delay-150 
                            hover:-translate-y-1 hover:scale-110  duration-300'>
                        <div className='flex place-content-center items-center'>
                            <TbPackages fontSize={18} />
                        </div>
                        <a href='#packages'>
                            <p className={`ml-2 font-semibold`} onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }}>PACKAGES</p>
                        </a>
                    </section>
                    <section className='text-white mt-5 flex flex-row cursor-pointer shadow-medium py-4 pl-2 transition ease-in-out delay-150 
                            hover:-translate-y-1 hover:scale-110  duration-300'>
                        <div className='flex place-content-center items-center'>
                            <RiMiniProgramLine fontSize={18} />
                        </div>
                        <a href='#programmeDetails'>
                            <p className={`ml-2 font-semibold`} onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }}>PROGRAMME DETAILS</p>
                        </a>
                    </section>
                    <section className='text-white mt-5 flex flex-row cursor-pointer shadow-medium py-4 pl-2 transition ease-in-out delay-150 
                            hover:-translate-y-1 hover:scale-110  duration-300'>
                        <div className='flex place-content-center items-center'>
                            <FaQuestionCircle fontSize={18} />
                        </div>
                        <a href='#faq'>
                            <p className={`ml-2 font-semibold`} onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true) }}>FAQ</p>
                        </a>
                    </section>
                </>}
                <section className='text-white mt-5 flex flex-row cursor-pointer shadow-medium py-4 pl-2 transition ease-in-out delay-150 
                            hover:-translate-y-1 hover:scale-110  duration-300'>
                    <div className='flex place-content-center items-center'>
                        <GiArchiveRegister fontSize={18} />
                    </div>

                    <p className={`ml-2 text-sm  text-[#c5c500] font-semibold`} onClick={() => { setShowHamburgerItems(false); setShowCloseButton(false); setShowHamburgerButton(true); setRegisterButtonModal(true) }}>REGISTER</p>

                </section>
                <section className='mt-[5rem] pl-2 max-[299px]:pl-0 '>
                    <div className='flex content-between'>
                        <a href='https://twitter.com/elevatenetwork1' target='blank' className='p-nav p-2 rounded-full'><RiTwitterXFill color='white' /></a>
                        <a href='https://www.instagram.com/elevatenetworkhq' target='blank' className='ml-[4rem]  max-[299px]:ml-2 p-nav p-2 rounded-full'><TfiInstagram color='white' /></a>
                        <a href='https://www.linkedin.com/company/elevatenetwork/' target='blank' className='ml-[4rem]  max-[299px]:ml-2 p-nav p-2 rounded-full'><LiaLinkedinIn color='white' /></a>
                    </div>
                </section>
            </div>
        </main>
    </>
}

export default HamburgerItems


