




const ImageViewer = () => {


    return <>
        <div className="overflow-hidden relative bg-[#f1fcff]  pt-[16rem] max-[1413px]:pt-[16rem] max-[1024px]:pt-[20rem] max-[1285px]:pt-[8rem] max-[724px]:pt-[12.5rem] max-[572px]:pt-[17rem] max-[400px]:pt-[13rem] ">
            <div className="flex transition-transform animate-normal_carousel duration-500 ease-in-out -translate-x-screen">
               
            </div>
        </div>
    </>
}

export default ImageViewer;