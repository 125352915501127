
import { useInView } from 'react-intersection-observer';
import FAQItem from './FAQItem';


const FAQ = () => {

    const [ref, inView] = useInView({

    });

    const faq1 = [
        {
            question: 'Is the event venue confirmed ?',
            answer: 'Yes, it will take place at Anagkazo, Mampong-Akuapem and Papaye Recreational Village',
            id: '1'
        },
        {
            question: 'What are the festival dates ?',
            answer: 'The festival is scheduled for October 9th to October 13th, 2024',
            id: '2'
        },
        {
            question: 'What age is allowed ?',
            answer: 'Gen Z (11-22 years)',
            id: '3'
        },
        {
            question: 'What time is the last entry?',
            answer: '7:00pm',
            id: '4'
        },
    ]

    const faq2 = [

        {
            question: 'Where is the festival taking place ?',
            answer: 'Anagkazo, Mampong-Akuapem and Papaye Recreational Village',
            id: '5',
        },
        {
            question: 'What are the festival times ?',
            answer: '9:30am morning session , 7:00pm evening experience',
            id: '6',
        },
        {
            question: 'Do you have discounts for students?',
            answer: 'Yes, All 2021 power of you ticket holders have a discount',
            id: '7'
        },

    ]

    return <>
        <main className="flex-grow  container overflow-y-scroll overflow-x-hidden hide-vertical-scrollbar " ref={ref}>
            <section className=' h-[35rem] py-[2rem]'>
                    <section>
                        {faq1.map((item) => <FAQItem item={item} key={item.id} />)}
                    </section>
                    <section className={`transition-transform duration-4000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                        }`}> 
                        {faq2.map((item) => <FAQItem item={item} key={item.id} />)}
                    </section>
            </section>
        </main>
    </>
}

export default FAQ;