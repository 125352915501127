
import { FaArrowAltCircleRight } from "react-icons/fa";
import { GiArchiveRegister } from 'react-icons/gi'
import { useLocation,Link} from "react-router-dom";
import {useState, useEffect, useContext, useMemo} from 'react';
import DataContext from "../../context/DataContext";


const RegisterButton = () => {

    const [hidden, setHidden] = useState('')
    const [showRegButton, setShowRegButton] = useState(true)
    const [currentIndex, setCurrentIndex] = useState(0);
    const {setRegisterButtonModal} = useContext(DataContext)

    const location = useLocation()
    
    const buttonItems = useMemo(()=> ["Register Now"],[])  //"Make Payment"

    useEffect(() => {

        if (location.pathname === '/register' || location.pathname ==='/payment' || location.pathname ==='/gift' ) {
              setHidden('hidden')
            }
        else{
            setHidden('')
        }

     }, [location.pathname])

     useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % buttonItems.length);
      }, 2000);
  
      return () => clearInterval(interval);
    }, [buttonItems]);

     

     useEffect(() => {
       const handleScroll = () => {
         const scrolledToBottom =
           window.innerHeight + window.scrollY >= document.body.offsetHeight;
   
         setShowRegButton(!scrolledToBottom);
       };
   
       window.addEventListener('scroll', handleScroll);
   
       return () => {
         window.removeEventListener('scroll', handleScroll);
       };
     }, []);


     const registerHandler = ()=>{
        
        //setRegisterButtonModal(true)

     }
    
    
    return <>
        {showRegButton && 
            <Link to='/register'><main className={`container flex justify-end font-bold max-h:hidden ${hidden}`}>
                <button className="bg-gradient-to-r from-indigo-500 from-10% via-sky-500 via-30% to-emerald-500 to-90% hover:from-[#e9edcd] hover:to-[#474d8a] 
                    rounded-md fixed bottom-[5rem]  z-10 p-2 w-[15rem] text-white flex place-content-center items-center hover:text-[black]"
                     onClick={registerHandler}
                    >
                    <GiArchiveRegister className="mr-2 animate-bounce" />
                     {buttonItems[currentIndex]}
                    <FaArrowAltCircleRight className="ml-2" /></button>
            </main></Link>
        }
    </>
}

export default RegisterButton;