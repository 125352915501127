

import { useContext } from "react";
import DataContext from "../../../../../context/DataContext";
import api from '../../../../../api/DataApi';



const ScholarshipSubmit = ({ setScholarshipError, setShowScholarshipRegSuccess, 
    setSelectedConditions, setNchecked, setYchecked, setApplicationError, setApplicationErrorMessage }) => {

    const { scholarshipState, scholarshipDispatch } = useContext(DataContext)

    const scholStates = [
        scholarshipState.qualification,
        scholarshipState.first_name,
        scholarshipState.last_name,
        scholarshipState.phone_number,
        scholarshipState.email,
        scholarshipState.age,
        scholarshipState.gender,
        scholarshipState.address,
        scholarshipState.city,
        scholarshipState.region,
        scholarshipState.zip_code,
        scholarshipState.attended_before,
        scholarshipState.reason_for_application
    ]

    const submitScholarship = async () => {

        const scholarshipPayload = {

            first_name: scholarshipState.first_name,
            last_name: scholarshipState.last_name,
            gender: scholarshipState.gender,
            age: parseInt(scholarshipState.age),
            attended_before: scholarshipState.attended_before,
            address: scholarshipState.address,
            city: scholarshipState.city,
            region: scholarshipState.region,
            zip_code: scholarshipState.zip_code,
            phone_number: scholarshipState.phone_number,
            email: scholarshipState.email,
            reason_for_application: scholarshipState.reason_for_application,
            qualification: scholarshipState.qualification,
        }


        try {

            const response = await api.post('scholarship', scholarshipPayload)
            console.log(response)

            setShowScholarshipRegSuccess(true)
            setTimeout(() => setShowScholarshipRegSuccess(false), 2500)
            
             //clear defaults
             scholarshipDispatch({ type: 'first_name', payload: '' })
             scholarshipDispatch({ type: 'last_name', payload: '' })
             scholarshipDispatch({ type: 'gender', payload: '' })
             scholarshipDispatch({ type: 'age', payload: '' })
             scholarshipDispatch({ type: 'attended_before', payload: '' })
             scholarshipDispatch({ type: 'address', payload: '' })
             scholarshipDispatch({ type: 'city', payload: '' })
             scholarshipDispatch({ type: 'region', payload: '' })
             scholarshipDispatch({ type: 'zip_code', payload: '' })
             scholarshipDispatch({ type: 'phone_number', payload: '' })
             scholarshipDispatch({ type: 'email', payload: '' })
             scholarshipDispatch({ type: 'reason_for_application', payload: '' })
             scholarshipDispatch({ type: 'first_name', payload: '' })
             setSelectedConditions([])
             setNchecked(false)
             setYchecked(false)

        } catch (err) {

            if (err) {
                console.log(err.response.data)
                setApplicationErrorMessage('please register first')
                console.log(err.response.status)
                console.log(err.response.headers)

                setApplicationError(true)
                setTimeout(() => setApplicationError(false), 2000)
            }
        }
    }


    const submitHandler = async() => {

        const isEmpty = scholStates.some((item) => item === '')

        if (isEmpty) {

            setScholarshipError(true)
            setTimeout(() => setScholarshipError(false), 1500)

        } else {

            submitScholarship()
        }

    }


    return (<>
        <button className="py-2 w-[40%]  bg-[#5030d2] text-[1.2rem] rounded-xl text-white p-4 small_pixel  cursor-pointer hover:bg-[blue]"
            onClick={submitHandler}
        >
            SUBMIT FORM
        </button>
    </>)
}


export default ScholarshipSubmit;