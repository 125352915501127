
import { useInView } from 'react-intersection-observer';
// import culture from '../../../../images/culture.jpg';
//import img12 from '../../../../images/carousel/img3.jpg';
import gathering from '../../../../images/gathering.jpg'



const DesireofFuture = () => {

    const [ref, inView] = useInView({

    });

    return (
        <>
            <main className="bg-[#f1fcff]" ref={ref}>
                <section className="  container pt-[3%] pb-[3%] " >
                    <div className='flex items-center place-content-center max-[860px]:flex max-[860px]:flex-col'>
                        <div className='w-[35rem] min-[860px]:mr-2 max-[860px]:w-[100%] relative'>
                            <p className="text-xl herokid-regular font-bold">A GATHERING OF 11-22 YEAR OLDS</p>
                            <h1 className=" tracking-[0.5rem]  w-[100%]  text-[6rem]  max-[770px]:text-[4rem] max-[401px]:text-[3rem]  leading-[6rem] max-[770px]:leading-[4rem] max-[401px]:leading-[3rem] pt-2 herokid-bold font-bold">
                                THE <p>DESIRE</p><p className={`transition-transform duration-2000 transform ${inView ? 'translate-y-0' : '-translate-y-full'
                                    }`}>OF OUR FUTURE</p>
                            </h1>
                            <p className={` small_pixel pt-3 text-[20px] md:leading-[2rem] transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                                } `}>From its start in 2018, the Power of You movement has a singular mission
                                calling students and leaders from campuses across the nation, cities around the world to live for
                                what matters most.
                            </p>
                            <p className={` small_pixel pt-3 text-[20px] md:leading-[2rem] transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                                } `}>

                                For us, what matters most is the opportunity for younger generations to make a difference in their communities!
                            </p>
                        </div>
                        <div className='w-[30%] max-[860px]:w-[100%]'>
                            <img src={gathering} alt="gathering.jpg" className='w-[100%] h-[41rem] max-[630px]:h-[20rem] object-cover rounded-xl transition ease-in-out delay-150 
                                hover:-translate-y-1 hover:scale-110  duration-1000' />
                        </div>
                    </div>

                    {/* <div>
                        <h1 className='lg:text-xl swell-Regular pt-[4rem] max-[430px]:text-lg max-[430px]:text-[1rem] max-[1027px]:text-md  max-[430px]:pt-[2rem] '>TARGET AUDIENCE</h1>
                        <div className={`flex justify-between   py-2 transition-transform duration-2000 transform ${inView ? '-translate-y-0' : 'translate-y-full'
                            }`}>
                            <section className='flex justify-between '>
                                <img src={gender} width={50} alt="gender.png" className='max-[553px]:hidden  w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                                <div className='px-2'>
                                    <p className='text-lg swell-Regular'>Gender</p>
                                    <p className='font_family_text md:text-[17px]'>Female : 70%</p>
                                    <p className='font_family_text md:text-[17px]'>Male : 30%</p>
                                </div>

                            </section>
                            <section className='flex justify-between '>
                                <img src={schoollevel} alt="level.png" width={50} className='max-[553px]:hidden  w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                                <div className='px-2'>
                                    <p className='text-lg swell-Regular'>Level</p>
                                    <p className='font_family_text  md:text-[17px]'>High School : 85%</p>
                                    <p className='font_family_text  md:text-[17px]'>University : 15%</p>
                                </div>
                            </section>
                            <section className='flex justify-between  '>
                                <img src={age} alt="age.png" width={50} className='max-[553px]:hidden  w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                                <div className='px-2'>
                                    <p className='text-lg swell-Regular'>Age</p>
                                    <p className='font_family_text md:text-[17px]'>14-18 : 80%</p>
                                    <p className='font_family_text md:text-[17px]'>18-22 : 20%</p>
                                </div>
                            </section>
                        </div>
                    </div> */}
                    {/* <section>
                        <h1 className='lg:text-xl swell-Regular pt-[3rem] max-[430px]:text-lg max-[430px]:text-[1rem] max-[1027px]:text-md  max-[430px]:pt-[1rem] '>OUR COLLECTIVE IMPACT</h1>
                        <p className={` font_family_text pt-2 text-[17px] md:leading-[2rem] transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                            } `}>Since the first gathering in 2019, Power of You has had the privilege of encountering thousands of
                            students and 18-25 years old, a sea of young people whose lives have been transformed by our programs. Their
                            voice is getting louder as they rise, united as generation, bringing hope and light to the world.
                        </p>
                    </section> */}
                </section>
            </main>
        </>
    )
}

export default DesireofFuture;