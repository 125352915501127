

import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Nav from '../nav/Nav'
import Home from "./mainbodyItems/Home";
import Register from "./mainbodyItems/Register";
import Payment from "./mainbodyItems/Payment";
import GiftPayment from "./mainbodyItems/RegisterRouteItems/GiftPayment";
import RegisterButton from "./RegisterButton";
import HamburgerItems from "./HamburgerItems";
import Scholarships from "./mainbodyItems/RegisterRouteItems/Scholarships";
import ScholarshipForm from "./mainbodyItems/RegisterRouteItems/scholarshipItems/ScholarshipForms";
import Footer from "./mainbodyItems/Footer";
import ContactUsModal from "./ContactUsModal";
import ContactUsSuccessModal from "./contactUsItems/ContactUsSuccessModal";
import RegisterButtonModal from "./RegisterButtonModal";
import DataContext from "../../context/DataContext";



const MainBody = () => {

    const { showHamburgerItems,showContactUsModal, showEmailSuccess, registerButtonModal, regUser} = useContext(DataContext)

    return <>
        <main className="min-h-screen flex flex-col overflow-x-hidden">
        <Nav />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/register' element={<Register />} />
                <Route path='/payment' element={regUser? <Payment /> : <Navigate to = "/"/>} />
                <Route path='/gift' element={<GiftPayment />} />
                <Route path='/scholarship' element={<Scholarships/>}/>
                <Route path='/scholarshipform' element={<ScholarshipForm/>}/>
            </Routes>
            {showHamburgerItems && <HamburgerItems />}
            <RegisterButton />
            {registerButtonModal && <RegisterButtonModal/>}
            <Footer />
            {showContactUsModal && <ContactUsModal/>}
            {showEmailSuccess && <ContactUsSuccessModal />}
        </main>
    </>
}

export default MainBody;