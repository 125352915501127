
import { useInView } from 'react-intersection-observer';
import age from '../../../../images/desireOfFuture/age.png';
import gender from '../../../../images/desireOfFuture/gender.png';
import schoollevel from '../../../../images/desireOfFuture/schoollevel.png';



const TargetAudience = () => {

    const [ref, inView] = useInView({

    });

    return <>

        <main className="target-audience">
            <div ref={ref} className='container'>
                <h1 className='text-[1.8rem] lg:text-[2rem] herokid-bold font-bold pt-[4rem] pb-[0.8rem]  max-[430px]:text-[1.5rem] max-[1027px]:text-md  max-[430px]:pt-[2rem] text-[white] '>TARGET AUDIENCE</h1>
                <div className={`flex justify-between   py-2 transition-transform duration-2000 transform ${inView ? '-translate-y-0' : 'translate-y-full'
                    }`}>
                    <section className='flex justify-between shadow-xlarge rounded-lg text-[white] p-4'>
                        <img src={gender}  width={50} alt="gender.png" className='max-[553px]:hidden  w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                        <div className='px-2'>
                            <p className='text-lg herokid-regular font-bold'>Gender</p>
                            <p className='small_pixel md:text-[17px]'>Female : 70%</p>
                            <p className='small_pixel md:text-[17px]'>Male : 30%</p>
                        </div>

                    </section>
                    <section className='flex justify-between shadow-xlarge rounded-lg lg:p-4 text-[white]'>
                        <img src={schoollevel} alt="level.png" width={50} className='max-[553px]:hidden w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                        <div className='px-2'>
                            <p className='text-lg herokid-regular font-bold'>Level</p>
                            <p className='small_pixel  md:text-[17px]'>High School : 85%</p>
                            <p className='small_pixel  md:text-[17px]'>University : 15%</p>
                        </div>
                    </section>
                    <section className='flex justify-between shadow-xlarge rounded-lg lg:p-4 text-[white] '>
                        <img src={age} alt="age.png" width={50} className='max-[553px]:hidden  w-[5rem] h-[4rem] md:w-[8rem] md:h-[5rem]' />
                        <div className='px-2'>
                            <p className='text-lg herokid-regular font-bold '>Age</p>
                            <p className='small_pixel md:text-[17px]'>11-18 : 80%</p>
                            <p className='small_pixel md:text-[17px]'>19-22 : 20%</p>
                        </div>
                    </section>
                </div>
            </div>
            <section className='container pb-[3rem]' ref={ref}>
                <h1 className='lg:text-[2rem] text-[1.8rem] herokid-bold font-bold pt-[3rem] max-[430px]:text-[1.5rem] max-[1027px]:text-md  max-[430px]:pt-[1rem] text-[white]'>OUR COLLECTIVE IMPACT</h1>
                <p className={` text-white small_pixel pt-2 text-[17px] md:leading-[2rem] transition-transform duration-2000 transform ${inView ? 'translate-x-0' : '-translate-x-full'
                    } `}>Since the first gathering in 2019, Power of You has had the privilege of encountering thousands of
                    students and 18-25 years old, a sea of young people whose lives have been transformed by our programs. Their
                    voice is getting louder as they rise, united as generation, bringing hope and light to the world.
                </p>
            </section>
        </main>
    </>
}


export default TargetAudience