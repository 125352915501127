
import { useState, useContext, useEffect } from 'react';
import conduct from '../../../../conductData/conduct.json';
import rules from '../../../../conductData/rules.json';
import DataContext from '../../../../context/DataContext';
import ScrollToTop from '../ScrollToTop';
import RegisterSuccessModal from './RegisterSuccessModal';
import { Bars } from 'react-loading-icons';

const CodeofConduct = () => {

  const [nChecked, setNchecked] = useState(false)
  const [yChecked, setYchecked] = useState(false)
  const [conductFieldError, setConductFieldError] = useState(false)
  const [conductChecked, setConductChecked] = useState(
    () => {
      const storedConduct = localStorage.getItem('conductChecked');
      return storedConduct === 'true';
    }
  )

  


  const { dispatch, personalInfoState, personalInfoStateDispatch, setRegister,showRegsuccess,isRegLoading,regErrorText } = useContext(DataContext)

  const conductStates = [
    personalInfoState.agree_to_code_of_conduct,
    personalInfoState.ward_code_of_conduct_signature,
    personalInfoState.parent_code_of_conduct_signature,
    personalInfoState.below_eighteen_years,
  ]

  const guardianStates = [
    personalInfoState.guardian_first_name,
    personalInfoState.guardian_last_name,
    personalInfoState.guardian_phone_number,
    personalInfoState.guardian_email,
    personalInfoState.relation,
    personalInfoState.relation_city,
    personalInfoState.relation_region,
    personalInfoState.relation_zip_code,
  ]


  const continuePageHandler = () => {

    const isEmpty = conductStates.some((item) => item === '')
    const guardianEmpty = guardianStates.some((item) => item === '')

    if (isEmpty || personalInfoState.agree_to_code_of_conduct === 'No') {
      setConductFieldError(true)
      setTimeout(() => setConductFieldError(false), 1500)

    } else if (personalInfoState.below_eighteen_years === 'Yes' && guardianEmpty) {
      setConductFieldError(true)
      setTimeout(() => setConductFieldError(false), 1500)
    } else {
      dispatch({ type: 'choosepackage', payload: true })
      dispatch({ type: 'codeofconduct', payload: false })
    }
  }


  const previousPageHandler = () => {
    dispatch({ type: 'insurance', payload: true })
    dispatch({ type: 'codeofconduct', payload: false })

  }

  useEffect(() => {
    localStorage.setItem('conductChecked', conductChecked);
    const payload = conductChecked ? 'Yes' : 'No';
    personalInfoStateDispatch({ type: 'agree_to_code_of_conduct', payload });
  }, [conductChecked, personalInfoStateDispatch]);


  const handleConduct = () => {
    setConductChecked(!conductChecked);
  };

  useEffect(()=>{

    if(nChecked){
      personalInfoStateDispatch({ type: 'guardian_first_name', payload: '' })
      personalInfoStateDispatch({ type: 'guardian_last_name', payload: '' })
      personalInfoStateDispatch({ type: 'guardian_phone_number', payload: '' })
      personalInfoStateDispatch({ type: 'guardian_email', payload: '' })
      personalInfoStateDispatch({ type: 'relation', payload: '' })
      personalInfoStateDispatch({ type: 'relation_city', payload: '' })
      personalInfoStateDispatch({ type: 'relation_region', payload: '' })
      personalInfoStateDispatch({ type: 'relation_zip_code', payload: '' })
    }

  },[nChecked, personalInfoStateDispatch])


  return <>
    <ScrollToTop />
    <main className="mt-1  w-[70%] max-[1280px]:w-[85%] max-[1024px]:w-[100%]  ">
      {showRegsuccess && <RegisterSuccessModal/>}
      <section className="mt-1 bg-white w-[100%] rounded-xl shadow-medium  ">
        <section className="px-5 py-5 small_pixel text-[18.5px]">
          <section>
            <section className='h-[30rem] overflow-x-hidden bg-[#F4F5F7] px-4'>
              <p className="pt-4  small_pixel text-[18.5px]">
                Parents, we’re excited to host your wards for POYF24! In order to help everyone have a safe
                and fun experience, please make sure you show this document to your ward so they understand
                what is expected of them at POYF24.
              </p>
              <div>
                <h2 className="text-[1.1rem] font-bold pt-4 herokid-bold">CODE OF CONDUCT</h2>
                {isRegLoading&&<Bars stroke="grey" height={50} fill="grey"  className=" absolute top-[70rem] left-0  right-0 mx-auto "/>}
                <p className=' small_pixel text-[18.5px]'>While attending POYF24, I will respect:</p>
                {conduct.map((item) => <div className="py-4" key={item.title}>
                  <p className='small_pixel text-[18.5px]'><span className='font-bold herokid-regular'>{item.title}--</span> {item.text}</p>
                </div>)}
              </div>
              <div>
                <h2 className="text-[1.1rem] font-bold pt-4 herokid-bold">Rules</h2>
                <p className='small_pixel text-[18.5px]'>Parents/Guardian, if your ward breaks any of these rules,
                  you may be called to pick him/her up from wherever he/she is at that time.
                  There will be no refunds if this happens.
                </p>
                {rules.map((item) => <div className="py-4" key={item.title}>
                  <p className='small_pixel text-[18.5px]'><span className='font-bold herokid-regular'>{item.title}--</span> {item.text}</p>
                </div>)}
              </div>
              <p className='small_pixel text-[18.5px]'><span className='font-bold text-[1.1rem] herokid-regular' >WARDS</span>: By signing this form, you are acknowledging that you understand the POYF24
                Code of Conduct and Rules. If you break any of these rules, a parent or guardian will be called
                to pick you up, and you will not attend the rest of POYF24.
              </p>
              <p className='small_pixel text-[18.5px]'><span className='font-bold text-[1.1rem] herokid-regular' >PARENTS</span>:By signing this form, you acknowledge that your ward understands
                the POYF24 Code of Conduct and Rules, and that you are responsible for picking him/her up
                if any rules are broken.
              </p>
            </section>
          </section>

          <section className="flex justify-between small_pixel text-[18.5px] py-4">
            <div className="w-[100%] flex items-center">
              <input type="checkbox" id='agreement'
                checked={conductChecked}
                onChange={handleConduct}
              />
              <label htmlFor="agreement" className=" px-2">I agree to all of the above terms and conditions.<sup className="text-[1rem]">*</sup> </label>
            </div>
          </section>

          <section className="small_pixel text-[18.5px] py-2">
            <div className="flex flex-col  w-[100%] ">
              <label htmlFor="code_conduct" className="">Ward Code Of Conduct E-Signature (type your full name):<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='code_conduct' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.ward_code_of_conduct_signature}
                onChange={(e) => personalInfoStateDispatch({ type: 'ward_code_of_conduct_signature', payload: (e.target.value) })}
              />
            </div>
          </section>

          <section className="small_pixel text-[18.5px] py-2">
            <div className="flex flex-col  w-[100%] ">
              <label htmlFor="code_conduct_guardian" className="">Parent/Guardian Code Of Conduct E-Signature (type your full name):<sup className="text-[1rem]">*</sup></label>
              <input type="text" id='code_conduct_guardian' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                value={personalInfoState.parent_code_of_conduct_signature}
                onChange={(e) => personalInfoStateDispatch({ type: 'parent_code_of_conduct_signature', payload: (e.target.value) })}
              />
            </div>
          </section>

          <section className="min-[624px]:flex min-[624px]:justify-between small_pixel text-[18.5px] py-2">
            <div className="  w-[48%]  max-[624px]:w-[100%]">
              <p className="">Are you below eighteen years of age?<sup className="text-[1rem]">*</sup></p>
            </div>
            <section className=" w-[48%]  max-[624px]:w-[100%] ">
              <section className="flex flex-row ">
                <div className="flex place-content-center items-center">
                  <input type="radio" id="group_yes" value='Yes'
                    checked={yChecked}
                    onChange={(e) => { setYchecked(true); setNchecked(false); personalInfoStateDispatch({ type: 'below_eighteen_years', payload: e.target.value }) }} />
                  <label htmlFor="group_yes" className="ml-2">Yes</label>
                </div>
                <div className="ml-[10%] flex place-content-center items-center">
                  <input type="radio" id="group_no" value='No'
                    checked={nChecked}
                    onChange={(e) => { setYchecked(false); setNchecked(true); personalInfoStateDispatch({ type: 'below_eighteen_years', payload: e.target.value }) }} />
                  <label htmlFor="group_no" className="ml-2">No</label>
                </div>
              </section>
            </section>
          </section>

          {yChecked && <>
            <section>
              <h2 className=' text-[1.1rem] pt-4'>Guardian Information</h2>
              <section className="flex justify-between small_pixel text-[18.5px] py-2">
                <div className="flex flex-col  w-[48%]">
                  <label htmlFor="firstName_guard" className="">First Name<sup className="text-[1rem]">*</sup></label>
                  <input type="text" id='firstName_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                    value={personalInfoState.guardian_first_name}
                    onChange={(e) => personalInfoStateDispatch({ type: 'guardian_first_name', payload: (e.target.value) })}
                  />
                </div>
                <div className="flex flex-col  w-[48%]">
                  <label htmlFor="lastName_guard" className="">Last Name<sup className="text-[1rem]">*</sup></label>
                  <input type="text" id='lastName_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                    value={personalInfoState.guardian_last_name}
                    onChange={(e) => personalInfoStateDispatch({ type: 'guardian_last_name', payload: (e.target.value) })}
                  />
                </div>
              </section>
            </section>

            <section className="flex justify-between small_pixel text-[18.5px] py-2">
              <div className="flex flex-col  w-[48%]">
                <label htmlFor="phoneNumber_guard" className="">Phone Number<sup className="text-[1rem]">*</sup></label>
                <input type="tel" id='phoneNumber_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                  value={personalInfoState.guardian_phone_number}
                  onChange={(e) => personalInfoStateDispatch({ type: 'guardian_phone_number', payload: (e.target.value) })}
                />
              </div>
              <div className="flex flex-col  w-[48%]">
                <label htmlFor="email_guard" className="">Email<sup className="text-[1rem]">*</sup></label>
                <input type="email" id='email_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                  value={personalInfoState.guardian_email}
                  onChange={(e) => personalInfoStateDispatch({ type: 'guardian_email', payload: (e.target.value) })}
                />
              </div>
            </section>

            <section className="small_pixel text-[18.5px] py-2">
              <div className="flex flex-col  w-[100%] py-2">
                <label htmlFor="relation" className="">Relation<sup className="text-[1rem]">*</sup></label>
                <input type="text" id='relation' className="border border-[grey] rounded-lg py-[0.15rem] px-2" autoComplete="on"
                  value={personalInfoState.relation}
                  onChange={(e) => personalInfoStateDispatch({ type: 'relation', payload: (e.target.value) })}
                />
              </div>
            </section>

            <section className="flex justify-between small_pixel text-[18.5px] py-2">
              <div className="flex flex-col  w-[30%]">
                <label htmlFor="city_guard" className="">City<sup className="text-[1rem]">*</sup></label>
                <input type="text" id='city_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                  value={personalInfoState.relation_city}
                  onChange={(e) => personalInfoStateDispatch({ type: 'relation_city', payload: (e.target.value) })}
                />
              </div>
              <div className="flex flex-col  w-[30%]">
                <label htmlFor="state_guard" className="">Region<sup className="text-[1rem]">*</sup></label>
                <input type="text" id='state_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                  value={personalInfoState.relation_region}
                  onChange={(e) => personalInfoStateDispatch({ type: 'relation_region', payload: (e.target.value) })}
                />
              </div>
              <div className="flex flex-col  w-[30%]">
                <label htmlFor="zip_guard" className="">Zip Code<sup className="text-[1rem]">*</sup></label>
                <input type="number" id='zip_guard' className="border border-[grey] rounded-lg py-[0.15rem] px-2"
                  value={personalInfoState.relation_zip_code}
                  onChange={(e) => personalInfoStateDispatch({ type: 'relation_zip_code', payload: (e.target.value) })}
                />
              </div>
            </section>
          </>}
          {conductFieldError && <p className="py-5 text-center text-[red] small_pixel text-[18.5px] ">all recommended fields (*) must be filled</p>}
          {regErrorText && <p className="py-5 text-center text-[red] small_pixel text-[18.5px]">{regErrorText}</p>}
          <div className="flex justify-between">
            <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18.5px]  cursor-pointer hover:bg-[blue]" onClick={previousPageHandler} >
              Previous
            </button>
            <button className="py-2  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18.5px]  cursor-pointer hover:bg-[blue]" onClick={()=>setRegister(true)}>   
              Register
            </button>
          </div>
        </section>
      </section>
    </main>
  </>
}

export default CodeofConduct;

//onClick={continuePageHandler}