

import { createContext, useState, useReducer,useEffect} from "react";
import emailjs from "@emailjs/browser";
import api from '../api/DataApi'
import {useNavigate} from 'react-router-dom';


const initialRegRenderStates = {
    personalInfo : true,
    insurance:false,
    codeOfConduct:false,
    choosePackage:false,
}

const initialContactStates = {

    firstName : '',
    lastName:'',
    phoneNumber:'',
    email:'',
    subject:'',
    message:'',
}

const initialPayStates = {
    firstName : '',
    lastName:'',
    phoneNumber:'',
    email:'',
    amount:'',
}

const initialGiftStates = {
    firstName : '',
    lastName:'',
    phoneNumber:'',
    email:'',
    amount:'',
}

const initialPersonalInfoStates = {
    
    first_name:'',
    last_name:'',
    phone_number:'',
    email:'',
    age:'',
    gender:'',
    campus:'',
    shirt_size:'',
    address:'',
    city:'',
    region:'',
    zip_code:'',
    in_egroup:'',
    days_of_week_grouping:'',
    egroup_leader:'',
    friends_to_group_with:'',
    medication:'',
    allergies:'',
    meal_plan:'',
    ward_has_special_needs:'',
    special_needs:'',
    wards_minor_injury_medication:'',
    medications_consent:'',
    agree_to_assumption_terms:'',
    assumption_of_risk_signature :'',
    agree_to_medical_waiver_terms:'',
    medical_wave_signature:'',
    ward_has_insurance:'',
    insurance_provider_name:'',
    insurance_provider_phone:'',
    agree_to_code_of_conduct:'',
    ward_code_of_conduct_signature:'',
    parent_code_of_conduct_signature:'',
    below_eighteen_years:'',
    guardian_first_name:'',
    guardian_last_name:'',
    guardian_phone_number:'',
    guardian_email:'',
    relation:'',
    relation_city:'',
    relation_region:'',
    relation_zip_code:'',
    
}


const initialScholarshipStates = {

    qualification : '',
    first_name:'',
    last_name:'',
    phone_number:'',
    email:'',
    age:'',
    gender:'',
    attended_before:'',
    address:'',
    city:'',
    region:'',
    zip_code:'',
    reason_for_application:'',

}




const reducer = (state, action)=>{

    switch(action.type){
        case'personalinfo' : return {...state, personalInfo:action.payload}
        case'insurance' : return {...state, insurance:action.payload}
        case'codeofconduct' : return {...state, codeOfConduct:action.payload}
        case'choosepackage' : return {...state, choosePackage:action.payload}

        default: throw new Error()
    }
}

const contactReducer = (state, action)=>{

    switch(action.type){
        case'firstName' : return {...state, firstName:action.payload}
        case'lastName' : return {...state, lastName:action.payload}
        case'phoneNumber' : return {...state, phoneNumber:action.payload}
        case'email' : return {...state, email:action.payload}
        case'subject' : return {...state, subject:action.payload}
        case'message' : return {...state, message:action.payload}
       

        default: throw new Error()
    }
}

const payReducer = (state, action)=>{

    switch(action.type){
        case'firstName' : return {...state, firstName:action.payload}
        case'lastName' : return {...state, lastName:action.payload}
        case'phoneNumber' : return {...state, phoneNumber:action.payload}
        case'email' : return {...state, email:action.payload}
        case'amount' : return {...state, amount:action.payload}

        default: throw new Error()
    }
}


const giftReducer = (state, action)=>{

    switch(action.type){
        case'firstName' : return {...state, firstName:action.payload}
        case'lastName' : return {...state, lastName:action.payload}
        case'phoneNumber' : return {...state, phoneNumber:action.payload}
        case'email' : return {...state, email:action.payload}
        case'amount' : return {...state, amount:action.payload}

        default: throw new Error()
    }
}

const personalInfoReducer = (state, action)=>{

    switch(action.type){
        
        // main person info
        case'first_name' : return {...state, first_name:action.payload}
        case'last_name' : return {...state, last_name:action.payload}
        case'gender' : return {...state, gender:action.payload}
        case'age' : return {...state, age:action.payload}
        case'campus' : return {...state, campus:action.payload}
        case'shirt_size' : return {...state, shirt_size:action.payload}
        case'address' : return {...state, address:action.payload}
        case'city' : return {...state, city:action.payload}
        case'region' : return {...state, region:action.payload}
        case'zip_code' : return {...state, zip_code:action.payload}
        case'phone_number' : return {...state, phone_number:action.payload}
        case'email' : return {...state, email:action.payload}
        case'in_egroup' : return {...state, in_egroup:action.payload}
        case'days_of_week_grouping' : return {...state, days_of_week_grouping:action.payload}
        case'egroup_leader' : return {...state, egroup_leader:action.payload}
        case'friends_to_group_with' : return {...state, friends_to_group_with:action.payload}
        case'medication' : return {...state, medication:action.payload}
        case'allergies' : return {...state, allergies:action.payload}
        case'meal_plan' : return {...state, meal_plan:action.payload}
        case'ward_has_special_needs' : return {...state, ward_has_special_needs:action.payload}
        case'special_needs' : return {...state, special_needs:action.payload}
        case'wards_minor_injury_medication' : return {...state, wards_minor_injury_medication:action.payload}
        case'medications_consent' : return {...state, medications_consent:action.payload}

        //insurance

        case'agree_to_assumption_terms' : return {...state, agree_to_assumption_terms:action.payload}
        case'assumption_of_risk_signature' : return {...state, assumption_of_risk_signature:action.payload}
        case'agree_to_medical_waiver_terms' : return {...state, agree_to_medical_waiver_terms:action.payload}
        case'medical_wave_signature' : return {...state, medical_wave_signature:action.payload}
        case'ward_has_insurance' : return {...state, ward_has_insurance:action.payload}
        case'insurance_provider_name' : return {...state, insurance_provider_name:action.payload}
        case'insurance_provider_phone' : return {...state, insurance_provider_phone:action.payload}

        //code of conduct
        case'agree_to_code_of_conduct' : return {...state, agree_to_code_of_conduct:action.payload}
        case'ward_code_of_conduct_signature' : return {...state, ward_code_of_conduct_signature:action.payload}
        case'parent_code_of_conduct_signature' : return {...state, parent_code_of_conduct_signature:action.payload}
        case'below_eighteen_years' : return {...state, below_eighteen_years:action.payload}

        //Guardian Information
        case'guardian_first_name' : return {...state, guardian_first_name:action.payload}
        case'guardian_last_name' : return {...state, guardian_last_name:action.payload}
        case'guardian_phone_number' : return {...state, guardian_phone_number:action.payload}
        case'guardian_email' : return {...state, guardian_email:action.payload}
        case'relation' : return {...state, relation:action.payload}
        case'relation_city' : return {...state, relation_city:action.payload}
        case'relation_region' : return {...state, relation_region:action.payload}
        case'relation_zip_code' : return {...state, relation_zip_code:action.payload}
               
       


        default: throw new Error()
    }
}


const scholarshipReducer = (state, action)=>{

    switch(action.type){
        
        case'first_name' : return {...state, first_name:action.payload}
        case'last_name' : return {...state, last_name:action.payload}
        case'gender' : return {...state, gender:action.payload}
        case'age' : return {...state, age:action.payload}
        case'attended_before' : return {...state, attended_before:action.payload}
        case'address' : return {...state, address:action.payload}
        case'city' : return {...state, city:action.payload}
        case'region' : return {...state, region:action.payload}
        case'zip_code' : return {...state, zip_code:action.payload}
        case'phone_number' : return {...state, phone_number:action.payload}
        case'email' : return {...state, email:action.payload}
        case'reason_for_application' : return {...state, reason_for_application:action.payload}
        case'qualification' : return {...state, qualification:action.payload}
        
        default: throw new Error()
    }
}


const DataContext = createContext({})

export const DataProvider = ({children})=>{

    const [showHamburgerItems, setShowHamburgerItems] = useState(false)
    const [showHamburgerButton, setShowHamburgerButton] = useState(true)
    const [showCloseButton, setShowCloseButton] = useState(false)
    const [textPersonColor, setTextPersonColor] = useState('text-[grey]')
    const [backgroundPersonColor, setBackgroundPersonColor] = useState('bg-[grey]')
    const [textInsuranceColor, setTextInsuranceColor] = useState('text-[grey]')
    const [backgroundInsuranceColor, setBackgroundInsuranceColor] = useState('bg-[grey]')
    const [textConductorColor, setTextConductorColor] = useState('text-[grey]')
    const [backgroundConductorColor, setBackgroundConductorColor] = useState('bg-[grey]')
    const [textPackageColor, setTextPackageColor] = useState('text-[grey]')
    const [backgroundPackageColor, setBackgroundPackageColor] = useState('bg-[grey]')
    const [packageValue, setPackageValue] = useState('')
    const [packageType, setPackageType] = useState('')
    const [showContactUsModal, setShowContactUs] = useState(false);
    const [showRegsuccess, setShowRegSuccess] = useState(false);
    const [showEmailSuccess, setShowEmailSuccess] = useState(false);
    const [fullPaymentValue, setFullPaymentValue] = useState('');
    const [registerButtonModal, setRegisterButtonModal] = useState(false)
    const [registrationCodeValue, setRegistrationCodeValue] = useState('')
    const [registrationEmail, setRegistrationEmail] = useState('')
    const [regUser, setRegUser] = useState(false)
    const [runVerification, setRunVerification] = useState(false)
    
    
    const [state, dispatch] = useReducer(reducer, initialRegRenderStates)
    const [contactState, contactDispatch] = useReducer(contactReducer, initialContactStates)
    const [payState, payDispatch] = useReducer(payReducer, initialPayStates)
    const [giftState, giftDispatch] = useReducer(giftReducer, initialGiftStates)
    const [personalInfoState, personalInfoStateDispatch] = useReducer(personalInfoReducer, initialPersonalInfoStates)
    const [scholarshipState, scholarshipDispatch] = useReducer(scholarshipReducer, initialScholarshipStates)
    const [register,setRegister]= useState(false)
    const [isRegLoading,setISRegLoading] = useState(false)
    const [regErrorText, showRegerrorText] = useState('')


    // const location = useLocation()
    const navigate = useNavigate()

   

    // useEffect(()=>{

    //     const locations = [

    //         '/', '/*', '/register', '/payment', '/gift', '/scholarship', '/scholarshipform'
    //     ]

    //   const isInValidUrl = locations.some((item)=>location.pathname === item)

    //   if(isInValidUrl){
    //     navigate('/missing')
    //   }

    // }, [location.pathname,  navigate])

    useEffect(() => {

        if (register) {
          console.log('it has started')
          const postRegister = async () => {
    
            // setIsLoading(true)
            setISRegLoading(true)
            const reg_code = `${personalInfoState.first_name.split('').slice(0, 3).join('')}${Math.floor((Math.random() * (58944584947584 - 15674737457) + 45678))}POY2024`
    
            const regPayload = {
    
              //main person info
              first_name: personalInfoState.first_name,
              last_name: personalInfoState.last_name,
              gender: personalInfoState.gender,
              age_field: parseInt(personalInfoState.age),
              campus: personalInfoState.campus,
              shirt_size: personalInfoState.shirt_size,
              address: personalInfoState.address,
              city: personalInfoState.city,
              region: personalInfoState.region,
              zip_code: personalInfoState.zip_code,
              phone_number: personalInfoState.phone_number,
              email: personalInfoState.email,
              in_egroup: personalInfoState.in_egroup,
              days_of_week_grouping: personalInfoState.days_of_week_grouping,
              egroup_leader: personalInfoState.egroup_leader,
              friends_to_group_with: personalInfoState.friends_to_group_with,
              medication: personalInfoState.medication,
              allergies: personalInfoState.allergies,
              meal_plan: personalInfoState.meal_plan,
              ward_has_special_needs: personalInfoState.ward_has_special_needs,
              special_needs: personalInfoState.special_needs,
              wards_minor_injury_medication: personalInfoState.wards_minor_injury_medication,
              medications_consent: personalInfoState.medications_consent,
              package_type: packageType,
    
              //insurance
              agree_to_assumption_terms: personalInfoState.agree_to_assumption_terms,
              assumption_of_risk_signature: personalInfoState.assumption_of_risk_signature,
              agree_to_medical_waiver_terms: personalInfoState.agree_to_medical_waiver_terms,
              medical_wave_signature: personalInfoState.medical_wave_signature,
              ward_has_insurance: personalInfoState.ward_has_insurance,
              insurance_provider_name: personalInfoState.insurance_provider_name,
              insurance_provider_phone: personalInfoState.insurance_provider_phone,
    
              //code of conduct
              agree_to_code_of_conduct: personalInfoState.agree_to_code_of_conduct,
              ward_code_of_conduct_signature: personalInfoState.ward_code_of_conduct_signature,
              parent_code_of_conduct_signature: personalInfoState.parent_code_of_conduct_signature,
              below_eighteen_years: personalInfoState.below_eighteen_years,
    
              //Guardian Information
              guardian_first_name: personalInfoState.guardian_first_name,
              guardian_last_name: personalInfoState.guardian_last_name,
              guardian_phone_number: parseInt(personalInfoState.guardian_phone_number),
              guardian_email: personalInfoState.guardian_email,
              relation: personalInfoState.relation,
              relation_city: personalInfoState.relation_city,
              relation_region: personalInfoState.relation_region,
              relation_zip_code: personalInfoState.relation_zip_code,
    
              //active code
              token: reg_code,
    
    
              //total amount
              total_amount_due: parseInt(packageValue ? packageValue : 0)
            }
    
            try {
    
              await api.post('register', regPayload);
    
            //   setIsLoading(false)
              setISRegLoading(false)
              setFullPaymentValue(parseInt(packageValue ? packageValue : 0))
    
              setShowRegSuccess(true)
              setTimeout(() => { setShowRegSuccess(false); setRegUser(true);navigate('/') }, 3000) //
    
              payDispatch({ type: 'firstName', payload: personalInfoState.first_name })
              payDispatch({ type: 'lastName', payload: personalInfoState.last_name })
              payDispatch({ type: 'email', payload: personalInfoState.email })
              payDispatch({ type: 'phoneNumber', payload: personalInfoState.phone_number })
    
              emailjs.send('service_mqc4n3r', 'template_ujqbv5j', {
                subject: "Registration Confirmation and Payment Details",
                to_email: personalInfoState.email,
                gratitude_note: "Thank you for registering",
                gratitude_message: "We are excited to have you join us for the upcoming Power of You Festival hosted by Elevate Network",
                message: "The Power of You Festival is dedicated to harnessing the potential of Gen Z through Art, Entrepreneurship, and Technology. Join us at the La Palm Royal Beach Hotel in Accra for the dynamic Power of You Festival 2024, filled with invaluable networking opportunities, learning experiences, collaborative creation, and a celebration of talents all under one roof. Your unique registration code for payment purposes is provided below:",
                registration_code: `Registration Code : ${reg_code}`,
                total_amount_due: `Total Amount Due : GH₵${0}`,   //parseInt(packageValue ? packageValue : 0)
                amount_remaining: `Amount Remaining: GH₵${0}`, //parseInt(packageValue ? packageValue : 0)
                message_ending: "Mark your calendar for 9th October, 2024, to ensure you don't miss out on this incredible event. Prepare to take charge of your life, happiness, and well-being like a true CEO!",
                from_name: "POYF2024",
                subscription: "We eagerly anticipate your presence",
                team_name: "TEAM ELEVATE NETWORK",
              }, '5sgg7jOC3tZwFvABh')
                .then(function (response) {
                  console.log("Email sent:", response);
                }, function (error) {
                  console.error("Error sending email:", error);
                });


    
            } catch (err) {
    
              if (err.response) {
    
                console.log(err.response.data)
                console.log(err.response.status)
                console.log(err.response.headers)
                // setIsLoading(false)
                setISRegLoading(false)
              }
    
              if (err.response.status === 409) {
                // setIsLoading(false)
                setISRegLoading(false)
                showRegerrorText(err.response.data.error)
    
                // setShowRegError(true)
                // setTimeout(() => setShowRegError(false), 1000)
                setTimeout(() => showRegerrorText(""), 1000)
    
              }
            }
    
          }
          postRegister()
        }
        setRegister(false)
      }, [register])

    
   
    return (
        <DataContext.Provider value={{
            showHamburgerItems, setShowHamburgerItems,showHamburgerButton, setShowHamburgerButton,
            showCloseButton, setShowCloseButton, state, dispatch, textPersonColor, setTextPersonColor,
            backgroundPersonColor, setBackgroundPersonColor,textInsuranceColor, setTextInsuranceColor,
            backgroundInsuranceColor, setBackgroundInsuranceColor, textConductorColor, setTextConductorColor,
            backgroundConductorColor, setBackgroundConductorColor, textPackageColor, setTextPackageColor, 
            runVerification, setRunVerification, register,setRegister,isRegLoading,setISRegLoading, regErrorText, showRegerrorText,
            backgroundPackageColor, setBackgroundPackageColor,packageValue, setPackageValue, packageType, setPackageType, 
            contactState, contactDispatch, giftState, giftDispatch, personalInfoState, personalInfoStateDispatch, payState, payDispatch,showContactUsModal, setShowContactUs,
            scholarshipState, scholarshipDispatch, showRegsuccess, setShowRegSuccess, showEmailSuccess, setShowEmailSuccess,registrationEmail, setRegistrationEmail,
            fullPaymentValue, setFullPaymentValue, registerButtonModal, setRegisterButtonModal, registrationCodeValue, setRegistrationCodeValue, regUser, setRegUser,

        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;
