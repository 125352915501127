
import { useContext, useState, useEffect, useCallback } from "react";
import { Helmet } from 'react-helmet-async';
import DataContext from "../../../context/DataContext";
import PaymentPlanModal from "./PaymentItems/PaymentPlanModal";
import PaymentVerificationModal from "./PaymentItems/PaymentVerificationModal";
import PaystackPop from '@paystack/inline-js';
import api from "../../../api/DataApi";
import emailjs from '@emailjs/browser';



const Payment = () => {

    const { payState, payDispatch, fullPaymentValue, runVerification, setRunVerification } = useContext(DataContext)
    const [fieldError, setFieldError] = useState(false)
    const [nChecked, setNchecked] = useState(false)
    const [yChecked, setYchecked] = useState(false)
    const [showPartPaymentModal, setPartPaymentModal] = useState(false)
    const [paymentSuccesserrorMessage, setPaymentsuccessErrorMessage] = useState(false)
    const [verifyingProcess, setVerifyingProcess] = useState(false)
    const [showVerificationModal, setShowVerificationModal] = useState(false)
    const [retryButton, setShowRetryButton] = useState(false)
    const [transactionRef, setTransactionRef] = useState('')

    const publicKey = "pk_live_e55956868b5534b12ac9e070900fd6acf504c977";

    //parseInt(registrationValue ? registrationValue : 0) * parseInt(packageValue ? packageValue : 0)

    //email to be sent when someone makes a part payment
    const emailPartPayment = useCallback((response) => {

        emailjs.send('service_mqc4n3r', 'template_ujqbv5j', {
            subject: " Payment Confirmation and Details for Power of You Festival",
            to_email: payState.email,
            gratitude_note: "Thank you for your payment!",
            gratitude_message: "We are delighted to confirm your contribution toward the upcoming Power of You Festival hosted by Elevate Network.",
            message: "Your payment marks an essential step in securing your participation in this dynamic event dedicated to harnessing the potential of Gen Z through Art, Entrepreneurship, and Technology.",
            registration_code: `PAYMENT DETAILS`,
            total_amount_due: `Total Amount Paid : GH₵${response.data.data.amount_paid}`,
            amount_remaining: `Total Amount Left to be Paid: GH₵${parseInt(response.data.data.total_amount) - parseInt(response.data.data.amount_paid)}`,
            message_ending: "Make sure to mark your calendar for 9th October, 2024, to ensure your presence at this incredible festival. Prepare yourself to take charge of your life, happiness, and well-being like a true CEO!",
            from_name: "POYF2024",
            subscription: "We eagerly anticipate your presence",
            team_name: "TEAM ELEVATE NETWORK",
        }, '5sgg7jOC3tZwFvABh')
            .then(function (response) {
                console.log("Email sent:", response);
            }, function (error) {
                console.error("Error sending email:", error);
            });
    }, [payState.email])

    //email to be sent when a fullpayment is made

    const emailFullPayment = useCallback((response) => {

        emailjs.send('service_mqc4n3r', 'template_ujqbv5j', {
            subject: " Full Payment Received! Official Confirmation for Power of You Festival",
            to_email: payState.email,
            gratitude_note: "Congratulations!",
            gratitude_message: "We are thrilled to confirm your registration for the Power of You Festival hosted by Elevate Network, as your full payment has been successfully processed.",
            message: "Your commitment to this event, dedicated to empowering Gen Z through Art, Entrepreneurship, and Technology, is greatly appreciated.",
            registration_code: `CONFIRMATION DETAILS`,
            total_amount_due: `Total Amount Paid : GH₵${response.data.data.amount_paid}`,
            amount_remaining: `Total Amount Left to be Paid: GH₵${parseInt(response.data.data.total_amount) - parseInt(response.data.data.amount_paid)}`,
            message_ending: "Your registration is now officially confirmed, and you're all set to participate in this dynamic festival. Prepare yourself to take charge of your life, happiness, and well-being like a true CEO!",
            from_name: "POYF2024",
            subscription: "We eagerly anticipate your presence",
            team_name: "TEAM ELEVATE NETWORK",
        }, '5sgg7jOC3tZwFvABh')
            .then(function (response) {
                console.log("Email sent:", response);
            }, function (error) {
                console.error("Error sending email:", error);
            });
    }, [payState.email])

    const payment = useCallback(async () => {

        const trancPayload = {
            email: payState.email,
            token: transactionRef,
        }

        setShowVerificationModal(true)
        setVerifyingProcess(true)

        try {

            const response = await api.post('payment', trancPayload)

            const amount_left = parseInt(response.data.data.total_amount) - parseInt(response.data.data.amount_paid)

            console.log(response.data.data)
            console.log(amount_left)

            setVerifyingProcess(false)
            setPaymentsuccessErrorMessage('verification successful')
            setShowRetryButton(false)

            if (parseInt(amount_left) <= 0) {
                emailFullPayment(response)
            } else {
                emailPartPayment(response)
            }
            // email()

            setTimeout(() => {
                setShowVerificationModal(false)
                setPaymentsuccessErrorMessage('')
            }, 2000)

            setRunVerification(false)

            payDispatch({ type: 'firstName', payload: '' })
            payDispatch({ type: 'lastName', payload: '' })
            payDispatch({ type: 'phoneNumber', payload: '' })
            payDispatch({ type: 'email', payload: '' })
            payDispatch({ type: 'amount', payload: '' })

        } catch (err) {

            if (err) {

                console.log(err.response.data)
                console.log(err.response.status)
                console.log(err.response.headers)

                setVerifyingProcess(false)
                setPaymentsuccessErrorMessage('could not verify payment. please check your network and click on reload button below')
                setShowRetryButton(true)

            }
        }
    }, [payDispatch, payState.email, setRunVerification, transactionRef, emailFullPayment, emailPartPayment])

    useEffect(() => {
        if (runVerification) {
            payment()
        }
        setRunVerification(false)
    }, [payment, runVerification, setRunVerification])


    const payFormHandler = async (e) => {

        e.preventDefault()

        if (payState.firstName === '' || payState.lastName === '' || payState.email === '' || payState.amount === '' || payState.phoneNumber === '') {

            setFieldError(true)

            setTimeout(() => setFieldError(false), 1500)

        } else {

            const paystack = new PaystackPop()

            paystack.newTransaction({

                key: publicKey,
                amount: parseInt(payState.amount * 100),
                email: payState.email,
                firstname: payState.firstName,
                lastname: payState.lastName,
                phone: payState.phoneNumber,


                onSuccess(transaction) {

                    setTransactionRef(transaction.reference)

                    alert(`Payment Complete Reference ${transaction.reference}`)

                    setRunVerification(true)
                },

                onCancel() {
                    alert('transaction cancelled')
                }

            })
        }
    }


    return <>

        <Helmet>
            <title>Power of You 2024 - Payment</title>
            <meta name='description' content='pay for power of you 2024' />
            <link rel="canonical" href="https://powerofyoufestival.com/payment" />
        </Helmet>

        {showPartPaymentModal && <PaymentPlanModal setPartPaymentModal={setPartPaymentModal} setNchecked={setNchecked} />}
        {showVerificationModal && <PaymentVerificationModal
            verifyingProcess={verifyingProcess} paymentSuccesserrorMessage={paymentSuccesserrorMessage}
            showVerificationModal={showVerificationModal} retryButton={retryButton} setShowRetryButton={setShowRetryButton}
            setPaymentsuccessErrorMessage={setPaymentsuccessErrorMessage} setRunVerification={setRunVerification}
        />}
        <main className="mt-[6rem] flex-grow">
            <section className="background-element h-[28.5rem] ">
                <div className="container  h-[100%]  flex items-center place-content-center ">
                    <div className="text-white text-center">
                        <h1 className="herokid-bold font-bold tracking-[0.3rem] text-center text-white text-[4rem] max-[526px]:text-[3rem]">PAYMENT</h1>
                        <p className="small_pixel text-[1.3rem] max-[526px]:text-[1.1rem]">THE CAMP IS ALL INCLUSIVE!!!</p>
                        <p className="small_pixel text-[1.3rem] max-[526px]:text-[1.1rem]">YOUR REGISTRATION COVERS ALL FOOD , TRAVEL, LODGING AND PROGRAMMING</p>
                        <div className="container flex place-content-center ">
                            <p className="text-center py-[2.5rem] w-[50%] max-[526px]:w-[100%] small_pixel text-[1.25rem] max-[526px]:text-[0.9rem] ">
                                Registration will not be officially confirmed until payment of conference fees  are fully made
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container">
                <h1 className="text-[#5030d2] herokid-bold font-bold text-[2rem] pt-5">BILLING INFORMATION</h1>
                <section className=" flex place-content-center pt-[3rem] pb-[8rem]">
                    <form className="w-[60%] relative" >
                        {fieldError && <p className="py-5 text-center text-[red] small_pixel text-[18px] font-bold">all fields must be filled</p>}
                        <h1 className="text-[#5030d2] herokid-regular font-bold text-[1.5rem] pt-2 pb-5">Personal Details</h1>
                        <section className="small_pixel text-[18.5px] min-[624px]:flex min-[624px]:justify-between">
                            <div className="flex flex-col w-[48%] max-[624px]:w-[100%] ">
                                <label htmlFor="payment_firstName" className="">First Name<sup className="text-[1rem]">*</sup></label>
                                <input type="text" id='payment_firstName' className=" border-b-2 border-b-[grey] 
                                bg-[#f1f1f1] outline-none bg- py-[0.15rem] px-2"
                                    value={payState.firstName} onChange={(e) => payDispatch({ type: 'firstName', payload: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-col  w-[48%]  max-[624px]:w-[100%] max-[624px]:pt-3 ">
                                <label htmlFor="payment_lastName" className="">Last Name<sup className="text-[1rem]">*</sup></label>
                                <input type="text" id='payment_lastName' className="border-b-2 border-b-[grey] bg-[#f1f1f1] 
                                outline-none py-[0.15rem] px-2"
                                    value={payState.lastName} onChange={(e) => payDispatch({ type: 'lastName', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <section className="min-[624px]:flex min-[624px]:justify-between small_pixel text-[18px] pt-[2.5rem]">
                            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%]">
                                <label htmlFor="telnumber" className="">Phone Number<sup className="text-[1rem]">*</sup></label>
                                <input type="tel" id='telnumber' className="border-b-2 border-b-[grey] 
                                bg-[#f1f1f1] py-[0.15rem] outline-none px-2"
                                    value={payState.phoneNumber} onChange={(e) => payDispatch({ type: 'phoneNumber', payload: e.target.value })}
                                />
                            </div>
                            <div className="flex flex-col  w-[48%] max-[624px]:w-[100%] max-[624px]:pt-3">
                                <label htmlFor="payment_email" className="">Email<sup className="text-[1rem]">*</sup></label>
                                <input type="email" id='payment_email' className="border-b-2 border-b-[grey] bg-[#f1f1f1] outline-none py-[0.15rem] px-2" autoComplete="on"
                                    value={payState.email} onChange={(e) => payDispatch({ type: 'email', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <h1 className="text-[#5030d2] herokid-regular font-bold text-[1.5rem] pt-[2rem]">Select Payment Plan</h1>
                        <section className=" w-[100%] flex justify-start pt-[0.5rem] ">
                            <section className=" flex flex-col">
                                <div className="flex ">
                                    <input type="radio" id="full_payment" className="cursor-pointer"
                                        checked={yChecked}
                                        onChange={(e) => { setYchecked(true); setNchecked(false); payDispatch({ type: 'amount', payload: fullPaymentValue }) }} />
                                    <label htmlFor="full_payment" className="ml-2 small_pixel text-[18px]">make one time full payment</label>
                                </div>
                                <div className="flex pt-[1.5rem] ">
                                    <input type="radio" id="part_payment" className="cursor-pointer"
                                        checked={nChecked}
                                        onChange={(e) => { setYchecked(false); setNchecked(true); setPartPaymentModal(true) }} />
                                    <label htmlFor="part_payment" className="ml-2 small_pixel text-[18px]">make part payment</label>
                                </div>
                            </section>
                        </section>
                        <section className="small_pixel text-[18px] pt-[1rem]">
                            <div className="flex flex-col  w-[100%] py-2">
                                <label htmlFor="amount" className="">Amount(GH₵)<sup className="text-[1rem]">*</sup></label>
                                <input type="number" id='amount' className="border-b-2 border-b-[grey]
                                 bg-[#f1f1f1] outline-none py-[0.15rem] px-2" readOnly
                                    value={payState.amount} onChange={(e) => payDispatch({ type: 'amount', payload: e.target.value })}
                                />
                            </div>
                        </section>
                        <div className="flex justify-center pt-[2.5rem]">

                            <button className="py-2 w-[10rem]  bg-[#5030d2] rounded-xl text-white p-4 small_pixel text-[18px]  cursor-pointer hover:bg-[blue]"
                                onClick={payFormHandler}
                            >
                                Review and Pay
                            </button>
                        </div>
                    </form>
                </section>
            </section>
        </main>
    </>
}

export default Payment;