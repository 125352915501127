
//import { useContext } from "react";
import { GiCheckMark } from "react-icons/gi";
//import DataContext from "../../../../context/DataContext";


const ScholarshipRegisterSuccess = () => {

    // const {personalInfoState,setShowRegSuccess} = useContext(DataContext)

    return <>
        <div className="backdrop" ></div>

        <main className=" w-[32rem] max-[568px]:w-[100%] rounded-xl bg-white px-[1rem] modal-modal border border-3 border-[grey] fixed top-[23vh] left-[35%] max-[1080px]:left-[25%] max-[800px]:left-[15%] max-[700px]:left-[5%] max-[700px]:left-[auto] ">
            <h2 className="text-center font-bold text-[20px] small_pixel  pt-3">Scholarship Registration Successful</h2>
            <div className="flex place-content-center py-[2rem]">
                <span className="border border-[green] p-[1rem] rounded-[50%]">
                    <GiCheckMark color="green" />
                </span>

            </div>
            <div>
               
            </div>
        </main>
    </>
}

export default ScholarshipRegisterSuccess;