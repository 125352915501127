
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import scholaImage from '../../../../images/carousel/img6.jpg';


const Scholarships = () => {

    return <>

        <Helmet>
            <title>Power of You 2024 - Scholarship</title>
            <meta name='description' content='Apply for scholarship-power of You 2024' />
            <link rel="canonical" href="https://powerofyoufestival.com/scholarship" />
        </Helmet>

        <main className="flex flex-grow">
            <section className="container mt-[7.5rem]">
                <section className="flex justify-between max-[900px]:flex max-[900px]:flex-col pt-[2rem] pb-[4rem] max-[642px]:pb-[1rem]">
                    <div className='min-[900px]:w-[50%]  max-[900px]:w-[100%] '>
                        <h1 className="herokid-bold text-[2.95rem] leading-[2.8rem] font-bold">SCHOLARSHIPS FOR POWER OF YOU FESTIVAL 2024</h1>
                        <p className='small_pixel text-[20px] pt-1'>
                            The Power of You Festival consistently serves as a catalyst for profound and enduring transformations in the lives of countless youths each year,
                            precipitating both immediate and everlasting changes that reverberate beyond the event itself.
                        </p>

                        <p className='small_pixel text-[20px]  pb-[1rem] pt-[2rem]'>
                            Consequently, our goal is to eliminate financial barriers preventing individuals
                            from joining the Power of You Festival 2024 by offering scholarships to deserving students in need,
                            and here are two ways you can contribute to this initiative:
                        </p>
                        <ul className="flex flex-col list-disc list-inside small_pixel text-[20px]">
                            <li><Link to='/gift'><span className='herokid-regular font-bold text-[blue]'>Give a Scholarship :</span></Link> By contributing, you become the solution for 11-22-year-olds to attend
                                the event, for which we'd be immensely thankful.
                            </li>
                            <li><Link to="/scholarshipform"><span className='herokid-regular font-bold text-[blue]'>Apply for a Scholarship :</span></Link> If you're between 11 and 22 and seeking a means to attend Power of You Festival 2024, kindly fill out a scholarship application. </li>
                        </ul>
                        <p className='pt-[2rem] small_pixel text-[20px] font-bold'>
                            We're eagerly anticipating what is in store for us at Power of You Festival 2024,
                            and we hope you'll join us in facilitating students' presence or consider
                            applying for a scholarship to be part of the event.
                        </p>
                    </div>

                    <img src={scholaImage} alt="img9.jpg" className='min-[900px]:w-[46%] max-[900px]:mt-4  max-[900px]:w-[100%]  max-[630px]:h-[20rem] object-cover rounded-xl transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-1000 ' />
                </section>
                <section className='flex justify-between pb-[3rem]  max-[642px]:pb-[1rem]  max-[642px]:flex max-[642px]:flex-col'>
                    <div className='background-scholarship relative w-[38rem] min-[1539px]:w-[50rem] max-[642px]:w-[100%]'>
                        <h1 className='text-white herokid-bold font-bold text-center text-[2rem] mt-[8rem]'>APPLY FOR A SCHOLARSHIP </h1>
                        <p className='text-center px-[4rem] text-white small_pixel text-[20px]'>Join our efforts as we create opportunities for 15-22-year-olds to have an outstanding festival experience.</p>
                        <div className='flex place-content-center'>
                            <Link to="/scholarshipform" className='w-[16rem] absolute bottom-0 rounded-lg  py-2 px-2 text-white mt-2'>
                                <button className='w-[16rem] small_pixel absolute bottom-0 rounded-lg bg-[#5030d2]  py-2 px-2 text-white mt-2'>
                                    SUBMIT AN APPLICATION
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className='background-scholarshipright relative w-[38rem] min-[1539px]:w-[43rem] max-[642px]:w-[100%] max-[642px]:mt-2'>
                        <h1 className='text-white herokid-bold font-bold text-center text-[2rem] mt-[8rem]'>GIFT A SCHOLARSHIP </h1>
                        <p className='text-center px-[4rem] text-white small_pixel text-[20px] '>
                            Your generous contribution paves way for students to be present at Power of You Festival 2024, allowing them to experience this impactful event.</p>
                        <div className='flex place-content-center'>
                            <Link to='/gift' className='rounded-lg w-[16rem]  text-white mt-2'>
                                <button className='rounded-lg bg-[#5030d2] small_pixel  w-[16rem]  py-2 px-2 text-white mt-2'>
                                    GIFT A SCHOLARSHIP
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
            </section>

        </main>
    </>
}


export default Scholarships;